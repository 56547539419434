import BackToEvents from "@/assets/Exit.svg";
import GetAppModal from "@/components/odience/components/GetAppModal";
import OdienceProfileAvatar from "@/components/odience/components/OdienceProfileAvatar";
import GetAppButton from "@/components/shared/GetAppButton";
import LoginButton from "@/components/shared/LoginButton";
import { odienceColors } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { useToast } from "@/utils/helpers/toastManager";
import {
  OdienceEvent,
  OdienceUser,
} from "@/utils/hooks/useDirectorAuthentication";
import { css } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAtomValue } from "jotai";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import {
  filterStreamsByAccess,
  getUserPermissions,
  Stream,
  STREAM_TYPE_MAIN,
} from "../helpers/EventStreamUtils";

type EventStreamHeaderProps = {
  odienceAvatar: string;
  handleOdienceAvatarError: () => void;
  handleOpenEditProfile: () => void;
  event: OdienceEvent;
  selectedStreamId?: string;
  is360Stream?: boolean;
  availableEventStreams: Stream[];
  onNavigateBack: () => void;
  resetOdienceCallStates: () => void;
  callActive: boolean;
  onStreamChange: (newStream) => void;
  hideStreamSelection: boolean;
  updateStreamStatus: (selectedStream: Stream) => void;
  odienceUser: OdienceUser | null;
  onShowProvisioning: VoidFunction;
};

const EventStreamHeader = ({
  odienceAvatar,
  handleOdienceAvatarError,
  handleOpenEditProfile,
  event,
  selectedStreamId,
  is360Stream = false,
  availableEventStreams,
  onNavigateBack,
  resetOdienceCallStates,
  callActive,
  onStreamChange,
  hideStreamSelection,
  updateStreamStatus,
  odienceUser,
  onShowProvisioning,
}: EventStreamHeaderProps) => {
  const [isAppModalOpen, setAppModalOpen] = useState(false);
  const { dismissToast } = useToast();
  const isVideoWallCall = useAtomValue(atoms.odience.isVideoWallCall);

  const handleVideoWallActive = (event: PopStateEvent) => {
    event.preventDefault();
    const confirmLeave = window.confirm(
      `Are you sure you want to go back? You are currently on ${
        isVideoWallCall ? "the video wall" : "front row"
      }.`
    );

    if (confirmLeave) {
      resetOdienceCallStates();
      return true;
    }
    return false;
  };

  const backToEventsButton = (
    <button
      type="button"
      onClick={() => {
        if (callActive && !handleVideoWallActive(new PopStateEvent("popstate")))
          return;
        dismissToast?.();
        onNavigateBack();
      }}
      css={{
        flexShrink: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.5em",
      }}
    >
      <img
        src={BackToEvents}
        alt="Back to event list"
        css={{ height: "2em", width: "2em", objectFit: "contain" }}
      />
    </button>
  );

  const eventImage = (
    <div
      css={{
        flexShrink: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={event.imageUrl || event.organization_image_url}
        alt=""
        css={{
          borderRadius: "10px",
          height: "4.3em",
          width: "4.3em",
          objectFit: "cover",
        }}
      />
    </div>
  );

  const eventTitle = (
    <div
      css={{
        fontSize: "clamp(1rem, 1.5vw, 1.5rem)",
        textTransform: "uppercase",
        textOverflow: "ellipsis",
      }}
    >
      {event.name ?? "Unavailable"}
    </div>
  );

  return (
    <div
      css={{
        color: odienceColors.pureWhite,
        borderRadius: "20px",
        width: "100%",
        backgroundColor: odienceColors.onyxGray,
        display: "flex",
        gap: "1em",
        padding: "1em 2em",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all .2s ease",
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
        }}
      >
        {backToEventsButton}
        {eventImage}
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "0.3em",
            flexWrap: "wrap",
            textOverflow: "ellipsis",
          }}
        >
          {eventTitle}
          {selectedStreamId && (
            <StreamTitle
              streamId={selectedStreamId}
              streams={availableEventStreams}
              is360Stream={is360Stream}
              onStreamChange={onStreamChange}
              hideStreamSelection={hideStreamSelection}
              updateStreamStatus={updateStreamStatus}
              odienceUser={odienceUser}
              event={event}
            />
          )}
        </div>
      </div>

      <div
        css={{
          display: "flex",
          gap: "1em",
          alignItems: "center",
          flexWrap: "wrap-reverse",
          justifyContent: "center",
        }}
      >
        <div>
          <GetAppButton onGetAppModal={() => setAppModalOpen(true)} />
        </div>
        <div>
          {!isProvisioned() ? (
            <LoginButton onShowProvisioning={onShowProvisioning} />
          ) : (
            <OdienceProfileAvatar
              {...{
                odienceAvatar,
                handleOdienceAvatarError,
                handleOpenEditProfile,
              }}
            />
          )}
        </div>
      </div>

      <GetAppModal
        show={isAppModalOpen}
        onCloseModal={() => setAppModalOpen(false)}
        objEvent={event}
      />
    </div>
  );
};

const StreamTitle = ({
  streamId,
  streams,
  is360Stream,
  onStreamChange,
  hideStreamSelection,
  updateStreamStatus,
  odienceUser,
  event,
}: {
  streamId: string;
  streams: Stream[];
  is360Stream: boolean;
  onStreamChange: (newStream) => void;
  hideStreamSelection: boolean;
  updateStreamStatus: (selectedStream: Stream) => void;
  odienceUser: OdienceUser | null;
  event: OdienceEvent;
}) => {
  const dropdownMenuRef = useRef<HTMLDivElement>(null!);
  // User roles and permissions
  const { canViewPremiumStreams, canViewStaffStreams } = getUserPermissions(
    odienceUser,
    event
  );
  const premiumStreamImage = "/odience/event/stream/premiumStream.svg";
  const staffStreamImage = "/odience/event/stream/staffStream.svg";
  const defaultStreamImage = "/odience/event/stream/defaultStream.svg";

  // Find selected stream
  const stream = streams.find((s: Stream) => s.id === streamId) || null;
  const streamName = stream
    ? `${stream.info.name} ${stream.info.urls[0].resolution}`
    : "";
  const is360StreamImage = is360Stream
    ? "odience/event/stream/360Icon.svg"
    : "odience/event/stream/Planar.svg";

  const streamIcon = (stream: Stream) => {
    const icon =
      stream?.info.access_type === "premium"
        ? premiumStreamImage
        : stream?.info.access_type === "staff"
          ? staffStreamImage
          : defaultStreamImage;

    return <img src={icon} alt="stream Access Type" css={iconStyle} />;
  };

  const streamTextColor = (stream: Stream) => {
    const color =
      stream.info.access_type === "premium"
        ? odienceColors.warmOrange
        : stream.info.access_type === "staff"
          ? odienceColors.skyBlue
          : odienceColors.pureWhite;

    return color;
  };

  const filteredStreams = filterStreamsByAccess(
    streams,
    !!canViewPremiumStreams,
    !!canViewStaffStreams
  );

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleStreamChange = (selectedStream) => {
    onStreamChange((previous) => ({
      ...previous,
      main: {
        ...selectedStream,
        id: selectedStream.id,
        serverId: selectedStream.info.urls[0].serverId,
        is360Stream: selectedStream.info.is_360,
        isRunning: true,
        type: STREAM_TYPE_MAIN,
      },
    }));

    updateStreamStatus(selectedStream);
    setDropdownOpen(false);
  };

  useOnClickOutside(
    dropdownMenuRef,
    () => {
      setDropdownOpen(false);
    },
    "mouseup"
  );

  return (
    stream && (
      <div key={stream.id} css={containerStyle} ref={dropdownMenuRef}>
        <img
          src={is360StreamImage}
          alt={is360Stream ? "360 Stream" : "2D Stream"}
        />

        {filteredStreams.length > 1 && !hideStreamSelection ? (
          <div css={dropdownContainerStyle}>
            <div
              onClick={() => {
                setDropdownOpen(!isDropdownOpen);
              }}
              css={dropdownTriggerStyle}
            >
              <span css={{ color: streamTextColor(stream) }}>{streamName}</span>
              <ExpandMoreIcon
                css={[
                  expandIconStyle,
                  {
                    transform: isDropdownOpen
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  },
                ]}
              />
            </div>

            {isDropdownOpen && (
              <div css={dropdownMenuStyle}>
                {filteredStreams.map((s) => (
                  <div
                    key={s.id}
                    onClick={() => {
                      handleStreamChange(s);
                    }}
                    css={[
                      dropdownItemStyle,
                      {
                        color: streamTextColor(s),
                      },
                    ]}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        odienceColors.deepGray)
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        odienceColors.onyxGray)
                    }
                  >
                    {s.info.name} {s.info.urls[0].resolution} {streamIcon(s)}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <span
            css={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
              whiteSpace: "nowrap",
              color: streamTextColor(stream),
            }}
          >
            {streamName} {streamIcon(stream)}
          </span>
        )}
      </div>
    )
  );
};

export default EventStreamHeader;

const expandIconStyle = css({
  transition: "transform 0.3s ease",
  fontWeight: 700,
  height: "1.25em",
  width: "1.25em",
});

const containerStyle = css({
  fontSize: "clamp(0.75rem, 1vw, 1rem)",
  display: "flex",
  gap: "0.5em",
  alignItems: "center",
  position: "relative",
  width: "100%",
  flexWrap: "nowrap",
});

const dropdownContainerStyle = css({
  position: "relative",
  cursor: "pointer",
});

const dropdownTriggerStyle = css({
  fontSize: "1rem",
  background: odienceColors.onyxGray,
  padding: "5px 10px",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  gap: "0.5em",
});

const dropdownMenuStyle = css({
  position: "absolute",
  top: "100%",
  left: 0,
  background: odienceColors.onyxGray,
  borderRadius: "4px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
  whiteSpace: "nowrap",
  width: "max-content",
});

const dropdownItemStyle = css({
  padding: "8px 12px",
  cursor: "pointer",
  backgroundColor: odienceColors.onyxGray,
  transition: "background-color 0.3s ease",
  display: "flex",
  flexDirection: "row",
  gap: "0.5em",
  alignItems: "center",
  flexWrap: "nowrap",
});

const iconStyle = css({
  height: "1rem",
  width: "1rem",
});
