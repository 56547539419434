import NetworkState from "@/components/connectivityIssues/NetworkStatus";
import { odienceColors } from "@/styles/global.styles";
import { isMobile, openOdienceDownloadAppWebPage } from "@/utils/helpers/Utils";
import { ss } from "@/utils/helpers/sessionStorage";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { milliseconds } from "date-fns/milliseconds";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  CarouselOrientationType,
  FeedItemList,
  MediaPool,
  SelectedStreamsType,
  StreamCenterRef,
  StreamVideoRef,
  UserWallReactionsData,
} from "../helpers/EventStreamUtils";
import FeedItemCarousel, { FeedItemCarouselRef } from "./FeedItemCarousel";
import MobileStreamPrompt from "./MobileStreamPrompt";
import StreamReactions from "./StreamReactions";
import StreamVideo, { StreamVideoExtraMarginType } from "./StreamVideo";

type StreamCenterProps = {
  selectedStreams?: SelectedStreamsType;
  event: OdienceEvent;
  openFeedModal?: (itemId: any) => void;
  feedItems: FeedItemList;
  carouselOpen: boolean;
  carouselOrientation: CarouselOrientationType;
  selectMiniItem: (id: string) => void;
  selectedMiniItemId: string | null;
  reactionData: UserWallReactionsData | undefined;
  isLandscape: boolean;
  errorLoading: boolean;
  handleErrorLoading: () => void;
  standbyImage: string;
  streamDefaultRotation: number;
  onStreamActive: (active: boolean) => void;
  isStreamActive: boolean;
  mediaPool: MediaPool | null;
  onConnectionChange: (isPoorConnection: boolean) => void;
  lastVolume: number;
  setLastVolume: (value: number) => void;
};

const StreamCenter = ({
  ref,
  openFeedModal,
  selectedStreams,
  event,
  feedItems,
  carouselOpen,
  carouselOrientation,
  selectMiniItem,
  selectedMiniItemId,
  reactionData,
  isLandscape,
  errorLoading,
  handleErrorLoading,
  standbyImage,
  streamDefaultRotation,
  onStreamActive,
  isStreamActive,
  mediaPool,
  onConnectionChange,
  lastVolume,
  setLastVolume,
}: StreamCenterProps & {
  ref?: React.RefObject<StreamCenterRef>;
}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const mainStreamVideoRef = useRef<StreamVideoRef>(null);
  const pipStreamVideoRef = useRef<StreamVideoRef>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const feedItemCarouselRef = useRef<FeedItemCarouselRef>(null);
  const previousConnectionStatus = useRef<boolean | null>(null);
  const [isMainStreamFullScreen, setIsMainStreamFullScreen] = useState(false);
  const [extraMarginPipStreamVideo, setExtraMarginPipStreamVideo] =
    useState<StreamVideoExtraMarginType>({});
  const [mouseOverMainStream, setMouseOverMainStream] = useState(false);

  const handleContinueWeb = () => {
    setShowPrompt(false);
    ss.setSwipeToExplorePrompt("1");
  };

  const handleDownloadApp = () => {
    openOdienceDownloadAppWebPage();
    setShowPrompt(false);
  };

  useEffect(() => {
    if (ss.getSwipeToExplorePrompt() === null) {
      const promptTimeout = setTimeout(() => {
        setShowPrompt(true);
      }, 5000);

      return () => clearTimeout(promptTimeout);
    }
  }, []);

  useEffect(() => {
    if (feedItemCarouselRef.current && carouselOpen) {
      setExtraMarginPipStreamVideo({
        bottomLeftBottom:
          carouselOrientation === "horizontal"
            ? feedItemCarouselRef.current.getHeight()
            : 0,
        bottomLeftRight:
          carouselOrientation === "vertical"
            ? feedItemCarouselRef.current.getWidth()
            : 0,
      });
    }

    setExtraMarginPipStreamVideo((previous) => ({
      ...previous,
      topRight:
        mouseOverMainStream && mainStreamVideoRef.current
          ? mainStreamVideoRef.current.getHeaderHeight()
          : 0,
    }));
  }, [
    feedItemCarouselRef,
    carouselOrientation,
    carouselOpen,
    mouseOverMainStream,
  ]);

  useImperativeHandle(ref, () => ({
    stopStreams: () => {
      mainStreamVideoRef.current?.stopStream();
      pipStreamVideoRef.current?.stopStream();
    },
  }));

  const handleOpenFeedModal = (itemId: any) => {
    openFeedModal!(itemId);
  };

  const scrollCarousel = (direction: string) => {
    const scrollContainer = carouselRef.current;
    if (!scrollContainer) return;

    const scrollStep = 100;
    const isHorizontalScroll =
      scrollContainer.scrollWidth > scrollContainer.clientWidth;

    if (isHorizontalScroll) {
      if (direction === "right") {
        scrollContainer.scrollLeft += scrollStep;
      } else if (direction === "left") {
        scrollContainer.scrollLeft -= scrollStep;
      }
    } else {
      if (direction === "right") {
        scrollContainer.scrollTop += scrollStep;
      } else if (direction === "left") {
        scrollContainer.scrollTop -= scrollStep;
      }
    }
  };

  const handleMainStreamFullScreen = (fullScreen) => {
    setIsMainStreamFullScreen(fullScreen);
  };

  const handleMouseEnter = () => {
    setMouseOverMainStream(true);
  };

  const handleMouseLeave = () => {
    setMouseOverMainStream(false);
  };

  const activeStream = (
    <div
      css={{
        backgroundColor: isMobile()
          ? odienceColors.pitchBlack
          : odienceColors.midnightBlue,
        height: isMobile() && !isLandscape ? "50vh" : "100%",
        width: "100%",
      }}
    >
      <FeedItemCarousel
        feedItems={feedItems}
        carouselOpen={carouselOpen}
        carouselOrientation={carouselOrientation}
        scrollCarousel={scrollCarousel}
        selectMiniItem={selectMiniItem}
        selectedMiniItemId={selectedMiniItemId}
        carouselRef={carouselRef}
        ref={feedItemCarouselRef}
        isStreamFullScreen={isMainStreamFullScreen}
      />
      {reactionData && <StreamReactions reactionData={reactionData} />}
      <StreamVideo
        ref={mainStreamVideoRef}
        selectedStream={selectedStreams?.main}
        event={event}
        isLandscape={isLandscape}
        errorLoading={errorLoading}
        onErrorLoading={handleErrorLoading}
        standbyImage={standbyImage}
        streamDefaultRotation={streamDefaultRotation}
        onStreamActive={onStreamActive}
        onFullScreen={handleMainStreamFullScreen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isMouseHover={mouseOverMainStream}
        mediaPool={mediaPool}
        feedItems={feedItems}
        selectMiniItem={selectMiniItem}
        onOpenFeedModal={handleOpenFeedModal}
        lastVolume={lastVolume}
        setLastVolume={setLastVolume}
      >
        {/* Pip stream is only valid if a position has been provided */}
        {!isMobile() &&
          selectedStreams?.pip &&
          selectedStreams.pip.position && (
            <StreamVideo
              ref={pipStreamVideoRef}
              selectedStream={selectedStreams.pip}
              streamDefaultRotation={streamDefaultRotation}
              extraMargin={extraMarginPipStreamVideo}
              isParentFullScreen={isMainStreamFullScreen}
              isMouseHover={mouseOverMainStream}
            />
          )}
      </StreamVideo>
    </div>
  );

  const handleConnectionChange = (isPoorConnection: boolean) => {
    let isThrottling = false;

    if (previousConnectionStatus.current !== isPoorConnection) {
      if (!isThrottling) {
        // Send the connection status immediately
        onConnectionChange(isPoorConnection);
        previousConnectionStatus.current = isPoorConnection;

        // Start a 30-second timeout to throttle subsequent calls
        isThrottling = true;
        setTimeout(
          () => {
            isThrottling = false;
          },
          milliseconds({ seconds: 30 })
        );
      }
    }
  };

  return (
    <>
      <NetworkState
        v={mainStreamVideoRef}
        onConnectionStatusChange={(isPoorConnection) => {
          handleConnectionChange(isPoorConnection);
        }}
      />
      <div
        css={{
          position: "relative",
          height: isMobile() ? undefined : "100%",
          width: "100%",
        }}
      >
        {activeStream}

        {showPrompt && isMobile() && isStreamActive && (
          <MobileStreamPrompt
            handleContinueWeb={handleContinueWeb}
            handleDownloadApp={handleDownloadApp}
          />
        )}
      </div>
    </>
  );
};

export default StreamCenter;
