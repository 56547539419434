import { odienceColors } from "@/styles/global.styles";
import {
  generateRandomString,
  isSmartTVUserAgent,
} from "@/utils/helpers/Utils";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { ls } from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { useBlock } from "@/utils/hooks/useBlock";
import { useCall } from "@/utils/hooks/useCall";
import { useContacts } from "@/utils/hooks/useContacts";
import { DEFAULT_AVATAR } from "@/utils/hooks/useDirectorAuthentication";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import { displayChatScreenOverlay } from "@/utils/messaging/conversation/conversationUtils";
import { css } from "@emotion/react";
import { memo, useEffect, useState } from "react";
import { slideFromLeft, slideFromRight } from "../EventStream.style";
import { Participant } from "../helpers/EventStreamUtils";

type StreamParticipantsProps = {
  initialParticipants?: Participant[];
  isPanelOpen: boolean;
  closePanel: () => void;
  profileComplete: boolean;
  handleActionToParticipate: () => void;
};

const StreamParticipants = ({
  initialParticipants,
  closePanel,
  isPanelOpen,
  profileComplete,
  handleActionToParticipate,
}: StreamParticipantsProps) => {
  const [participants, setParticipants] = useState(initialParticipants);
  const [selectedParticipant, setSelectedParticipant] =
    useState<Participant | null>(null);
  const [isParticipantInfoOpen, setIsParticipantInfoOpen] = useState(false);

  const { canAcceptOrMakeCall, callWithAudio, callWithVideo } = useCall();
  const block = useBlock({
    contact: selectedParticipant
      ? WebGwContact.fromPhoneNumber(selectedParticipant.sip)
      : undefined,
  });
  const userId = isProvisioned()
    ? (ls.getUser() ?? "").replaceAll("+", "")
    : null;

  const contacts = useContacts();

  const isSmartTV = isSmartTVUserAgent(navigator.userAgent);

  const handleCall = (isVideo: boolean) => {
    if (block.value === "Unblock") {
      block.show("calling");
      return;
    }

    if (selectedParticipant) {
      if (isVideo) {
        callWithVideo({ number: selectedParticipant.sip });
      } else {
        callWithAudio({ number: selectedParticipant.sip });
      }
    }
    closePanel();
    closeInteract();
  };

  const sendMessageToUser = () => {
    if (block.value === "Unblock") {
      block.show("messaging");
      return;
    }

    if (selectedParticipant) {
      const contact =
        contacts?.findWithNumber(selectedParticipant.sip) ||
        WebGwContact.fromPhoneNumber(selectedParticipant.sip);

      const conversationId = Conversation.getOrCreate({
        phoneNumber: selectedParticipant.sip,
        contactToLinkIfCreate: contact,
      }).conversation.id;

      setSelectedConversationId(conversationId);
      displayChatScreenOverlay(conversationId);
    }
    closePanel();
    closeInteract();
  };

  const openParticipantInfoPanel = (participant: Participant) => {
    if (participant.id !== userId) {
      setSelectedParticipant(participant);
      setIsParticipantInfoOpen(true);
    }
  };

  const closeInteract = () => {
    setSelectedParticipant(null);
    setIsParticipantInfoOpen(false);
  };

  useEffect(() => {
    const updatedParticipants = initialParticipants?.map((p) => {
      if (!p.id || p.id === "undefined") {
        p.id = `guest-${generateRandomString(15)}`;
      }
      return p;
    });
    setParticipants(updatedParticipants);
  }, [initialParticipants]);

  const participantList = participants
    ?.filter((participant) => !participant.id.includes("guest-"))
    .map((participant) => (
      <div
        key={participant.id ?? generateRandomString(12)}
        onClick={() => ls.getUser() && openParticipantInfoPanel(participant)}
        css={participantContainerStyles}
      >
        <div css={avatarContainerStyles}>
          <img
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              e.currentTarget.src = DEFAULT_AVATAR;
            }}
            src={participant.avatar?.trim() || DEFAULT_AVATAR}
            alt={`${participant.name} avatar`}
          />
        </div>
        <div css={{ fontSize: "1.4rem", textAlign: "center" }}>
          {participant.id === userId
            ? `${participant.name} (me)`
            : participant.name}
        </div>
      </div>
    ));

  const guestParticipants = isProvisioned()
    ? (participants?.length ?? 0) - (participantList?.length ?? 0)
    : (participants?.length ?? 0);

  return (
    <>
      <div css={panelContainerStyles(isPanelOpen)}>
        <div css={headerStyles}>
          <div css={{ fontSize: "1.4rem" }}>Event Participants</div>
          <button type="button" onClick={closePanel} css={closeButtonStyles}>
            <img
              src="/odience/event/white-close.svg"
              alt="Close Participant Panel"
              css={{ minWidth: "1em" }}
            />
          </button>
        </div>
        <div
          css={{ overflowY: "auto", overflowX: "hidden", marginBottom: "5vh" }}
        >
          {profileComplete && isProvisioned() ? (
            <div css={participantListContainerStyles}>{participantList}</div>
          ) : (
            <div
              css={[
                loginPromptStyles,
                { height: "27.5vh", overflow: "hidden" },
              ]}
            >
              <div style={{ textAlign: "center" }}>
                <span onClick={handleActionToParticipate} css={loginLinkStyles}>
                  {isProvisioned() ? "Enter Display Name" : "Log in"}
                </span>
                to view event participants
              </div>
            </div>
          )}
          {guestParticipants > 0 && (
            <div css={guestCountStyles}>
              <div>{guestParticipants} guest(s) watching the event</div>
            </div>
          )}
        </div>

        {isParticipantInfoOpen && (
          <div css={participantInfoPanelStyles(isParticipantInfoOpen)}>
            <div css={{ fontSize: "1.2rem", padding: "1em" }}>
              <button
                type="button"
                onClick={closeInteract}
                css={backButtonStyles}
              >
                Go Back
              </button>
            </div>
            <div css={infoContainerStyles}>
              <div css={infoAvatarContainerStyles}>
                <img
                  onError={(
                    e: React.SyntheticEvent<HTMLImageElement, Event>
                  ) => {
                    e.currentTarget.src = DEFAULT_AVATAR;
                  }}
                  src={selectedParticipant?.avatar}
                  alt={`${selectedParticipant?.name}'s Avatar`}
                  style={{
                    height: "15vh",
                    width: "15vh",
                  }}
                />
              </div>
              <div css={{ fontSize: "2.5rem", fontWeight: "bold" }}>
                {selectedParticipant?.name}
              </div>
              <div css={interactionContainerStyles}>
                <button
                  type="button"
                  onClick={sendMessageToUser}
                  className="interactButton ripple"
                  css={interactButtonStyles}
                >
                  <img
                    src="/odience/event/send.svg"
                    alt="Send Message"
                    css={{ width: "1.25em" }}
                  />
                </button>
                {!isSmartTV && (
                  <button
                    type="button"
                    onClick={() => handleCall(true)}
                    className="interactButton ripple"
                    disabled={!canAcceptOrMakeCall}
                    css={interactButtonStyles}
                  >
                    <img
                      src="/odience/event/2d.svg"
                      alt="Video Call"
                      css={{ width: "1.25em" }}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {block.modal}
      </div>
    </>
  );
};

export default memo(StreamParticipants);

const panelContainerStyles = (isPanelOpen: boolean) =>
  css({
    position: "absolute",
    zIndex: 10,
    right: 0,
    backgroundColor: odienceColors.nightBlack,
    flexDirection: "column",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    width: "30vw",
    maxWidth: "30em",
    height: "100%",
    boxSizing: "border-box",
    animation: ` 0.3s forwards linear ${isPanelOpen ? slideFromRight : slideFromLeft}`,
    visibility: isPanelOpen ? "visible" : "hidden",
    display: "flex",
    color: odienceColors.pureWhite,
  });

const headerStyles = css({
  display: "flex",
  paddingLeft: "2.5em",
  paddingRight: "2.5rem",
  height: "9vh",
  minHeight: "4em",
  overflow: "hidden",
  alignItems: "center",
  backgroundColor: odienceColors.nightBlack,
});

const closeButtonStyles = css({
  marginLeft: "auto",
  cursor: "pointer",
  userSelect: "none",
});

const participantListContainerStyles = css({
  marginTop: "0.6em",
  width: "30vw",
  maxWidth: "30em",
  minWidth: "18em",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.6em",
  flexWrap: "wrap",
  overflowX: "hidden",
});

const loginPromptStyles = css({
  color: odienceColors.pureWhite,
  fontSize: "1.25rem",
  padding: "2em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  marginTop: "5em",
});

const loginLinkStyles = css({
  color: odienceColors.crimsonPink,
  textDecoration: "none",
  paddingRight: "0.5em",
  cursor: "pointer",
});

const guestCountStyles = css({
  bottom: 0,
  display: "flex",
  fontSize: "0.8rem",
  marginTop: "1.4em",
  padding: "1em 2.5em",
  position: "absolute",
  width: "100%",
});

const participantContainerStyles = css({
  color: odienceColors.pureWhite,
  flexShrink: 0,
  userSelect: "none",
  cursor: "pointer",
  maxWidth: "27em",
  minWidth: "20em",
  width: "27vw",
  paddingLeft: "1.4em",
  height: "5em",
  borderRadius: "20px",
  display: "flex",
  gap: "1.4em",
  alignItems: "center",
  backgroundColor: odienceColors.midnightBlue,
});

const avatarContainerStyles = css({
  width: "3em",
  height: "3em",
  borderRadius: "50%",
  overflow: "hidden",
});

const participantInfoPanelStyles = (isPanelOpen: boolean) =>
  css({
    color: odienceColors.pureWhite,
    position: "absolute",
    backgroundColor: odienceColors.nightBlack,
    right: 0,
    zIndex: 11,
    overflow: "hidden",
    flexDirection: "column",
    borderRadius: "10px",
    gap: "0.33em",
    width: "30vw",
    maxWidth: "31em",
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    animation: ` 0.3s forwards linear ${isPanelOpen ? slideFromRight : slideFromLeft}`,
  });

const backButtonStyles = css({
  gap: "0.8em",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

const infoContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const infoAvatarContainerStyles = css({
  marginTop: "1.4em",
  borderRadius: "50%",
  overflow: "hidden",
});

const interactionContainerStyles = css({
  marginTop: "4vh",
  display: "flex",
  gap: "7vh",
  alignItems: "center",
});

const interactButtonStyles = css({
  userSelect: "none",
  cursor: "pointer",
  width: "8vh",
  height: "8vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: odienceColors.shadowGray,
  borderRadius: "50%",
  ":hover": {
    transform: "scale(0.95)",
  },
  ":disabled": {
    color: odienceColors.coolGray,
    opacity: 0.5,
  },
});
