import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { generateEventListPath } from "@/utils/hooks/useOdienceEvents";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const COUNTDOWN_RESET = 120; // 2 minutes

const useUserInactivity = (
  setIsActiveUser: Dispatch<SetStateAction<boolean>>,
  setActiveUserCountdown: Dispatch<SetStateAction<number>>,
  isActiveUser: boolean,
  activeUserCountdown: number,
  objEvent: OdienceEvent,
  embeddedMode: boolean
): void => {
  const navigate = useNavigate();
  const lastTimeRef = useRef<number | null>(null);
  const [isTabActive, setIsTabActive] = useState(true);
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const resetInactivityTimer = () => {
      if (isActiveUser) {
        if (inactivityTimerRef.current) {
          clearTimeout(inactivityTimerRef.current);
        }

        lastTimeRef.current = Date.now();
        inactivityTimerRef.current = setTimeout(() => {
          setIsActiveUser(false);
          setActiveUserCountdown(COUNTDOWN_RESET);
        }, INACTIVITY_TIMEOUT);
      }
    };

    if (isActiveUser) {
      window.addEventListener("mousemove", resetInactivityTimer);
      window.addEventListener("keydown", resetInactivityTimer);
    }

    return () => {
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
    };
  }, [isActiveUser, setIsActiveUser, setActiveUserCountdown]);

  useEffect(() => {
    if (!isActiveUser && !isTabActive) {
      if (Notification.permission === "granted") {
        const notification = new Notification("You've been inactive!", {
          body: "Click here to return.",
          icon: "/odience/odience_logo_red.png",
        });

        notification.onclick = () => {
          window.focus();
        };
      } else if (Notification.permission !== "denied") {
        void Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const notification = new Notification("You've been inactive!", {
              body: "Click here to return.",
              icon: "/odience/odience_logo_red.png",
            });

            notification.onclick = () => {
              window.focus();
            };
          }
        });
      }
    }
  }, [isActiveUser]);

  useEffect(() => {
    if (isActiveUser || activeUserCountdown <= 0) {
      lastTimeRef.current = null;
      return;
    }

    if (!lastTimeRef.current) {
      lastTimeRef.current = Date.now();
    }

    const updateCountdown = () => {
      if (!lastTimeRef.current) return;
      const now = Date.now();
      const elapsedSeconds = Math.floor((now - lastTimeRef.current) / 1000);

      if (elapsedSeconds > 0) {
        setActiveUserCountdown((prev) => Math.max(prev - elapsedSeconds, 0));
        lastTimeRef.current = now;
      }
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [isActiveUser, activeUserCountdown, setActiveUserCountdown, objEvent]);

  useEffect(() => {
    if (activeUserCountdown === 0) {
      void navigate(generateEventListPath(objEvent, embeddedMode));
    }
  }, [activeUserCountdown, navigate, objEvent, embeddedMode]);
};

export { useUserInactivity };
