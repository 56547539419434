import { SuggestionResponse } from "@/components/chatScreen/chat/typings";
import { discardMessageNotificationAndOverlay } from "@/components/navigation/Navigation";
import { odienceColors } from "@/styles/global.styles";
import { isPollBot } from "@/utils/chatbots";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { useToast } from "@/utils/helpers/toastManager";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import { displayChatScreenOverlay } from "@/utils/messaging/conversation/conversationUtils/";
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { OdienceEvent } from "../../../../../utils/hooks/useDirectorAuthentication";

type EventPollProps = {
  socketRef: React.RefObject<SocketIOClient.Socket | null>;
  objEvent: OdienceEvent;
  eventId: string | undefined;
  profileComplete: boolean;
  onActionToParticipate: () => void;
};

const EventPoll = ({
  socketRef,
  objEvent,
  eventId,
  profileComplete,
  onActionToParticipate,
}: EventPollProps) => {
  const [pollStarted, setPollStarted] = useState(false);
  const [pollEnded, setPollEnded] = useState(false);
  const { showToast } = useToast();

  const findPollBot = (chatBotList: (typeof objEvent)["chatbots"]) => {
    return chatBotList.find(isPollBot);
  };

  const openPollBot = pollStarted
    ? async () => {
        if (!eventId) return;

        const pollBot = findPollBot(objEvent?.chatbots);
        if (!pollBot) {
          showToast(
            "Error loading the poll, please try again later",
            "top-center",
            "1em"
          );
          return;
        }
        const contact = WebGwContact.fromChatbotInfo(pollBot);
        const phoneNumber = contact.getMainPhoneNumber();
        const { conversation } = Conversation.getOrCreate({
          phoneNumber,
          contactToLinkIfCreate: contact,
        });

        setSelectedConversationId(conversation.id);
        displayChatScreenOverlay(phoneNumber);

        const sent = await conversation.sendTextMessage(
          JSON.stringify({
            response: {
              reply: {
                postback: {
                  data: pollBot.payload,
                },
                displayText: "Poll",
              },
            },
          } satisfies SuggestionResponse),
          true
        );

        if (sent) {
          setPollStarted(false);
        } else {
          console.error("Error sending poll message");
        }
      }
    : undefined;

  useEffect(() => {
    if (!socketRef.current || !isProvisioned()) return;

    const pollUpdatedHandler = (data: { value: boolean; bot_id: string }) => {
      console.debug("poll updated", data);

      if (data.value) {
        setPollStarted(true);
        setPollEnded(false);
      } else {
        setPollStarted(false);
        setPollEnded(true);
      }
    };
    socketRef.current.on("PollUpdated", pollUpdatedHandler);
    return () => {
      socketRef.current?.off("PollUpdated", pollUpdatedHandler);
    };
  }, [socketRef]);

  useEffect(() => {
    if (pollEnded) {
      // TODO - we should discard notification and overlay only for poll ended
      discardMessageNotificationAndOverlay();

      const timeout = setTimeout(() => {
        setPollEnded(false);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [pollEnded]);

  const pollText = (
    <div css={pollRowStyle}>
      <div css={pollIconStyle}>
        <img
          src="/odience/event/notify.svg"
          alt="Poll notification icon"
          css={{ width: "2em", height: "2em", transform: "rotate(180deg)" }}
        />
      </div>
      <div css={pollTextStyle}>Poll is about to start, vote now!</div>
    </div>
  );

  return (
    <>
      <div css={pollDivStyle(pollStarted)}>
        <div css={pollColStyle}>
          {profileComplete ? (
            pollText
          ) : (
            <div css={{ display: "flex" }}>
              {pollText}
              <button
                type="button"
                css={closeButtonStyle}
                onClick={() => setPollStarted(false)}
              >
                <CloseIcon css={closeIconStyle} />
              </button>
            </div>
          )}
          <div css={pollButtonRowStyle}>
            {profileComplete ? (
              <>
                <button
                  type="button"
                  onClick={() => setPollStarted(false)}
                  css={[pollButton]}
                >
                  Dismiss
                </button>
                <button
                  type="button"
                  onClick={openPollBot}
                  css={[
                    pollButton,
                    { backgroundColor: odienceColors.crimsonPink },
                  ]}
                >
                  Join Poll
                </button>
              </>
            ) : (
              <div css={enterDisplayNameStyle} onClick={onActionToParticipate}>
                Enter Display Name to participate
              </div>
            )}
          </div>
        </div>
      </div>
      <div css={pollDivStyle(pollEnded && !pollStarted)}>
        <div css={pollColStyle}>
          <div css={pollRowStyle}>
            <div css={pollIconStyle}>
              <img
                src="/odience/event/notify.svg"
                alt="Poll ended notification icon"
                css={{
                  width: "2em",
                  height: "2em",
                  transform: "rotate(180deg)",
                }}
              />
            </div>
            <div css={pollTextStyle}>Poll voting has ended!</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventPoll;

const pollButton = css({
  width: "7em",
  height: "3.5em",
  userSelect: "none",
});

const pollRowStyle = css({
  display: "flex",
  gap: "0.6em",
  alignItems: "center",
  justifyContent: "center",
});

const pollIconStyle = css({
  width: "3em",
  height: "3em",
  borderRadius: "50%",
  backgroundColor: odienceColors.shadowGray,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const pollTextStyle = css({
  marginRight: "0.6em",
  fontSize: "1.25rem",
  fontWeight: "bold",
});

const pollDivStyle = (visible: boolean) =>
  css({
    position: "absolute",
    color: odienceColors.pureWhite,
    bottom: "6%",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    visibility: visible ? "visible" : "hidden",
    pointerEvents: visible ? "initial" : "none",
    zIndex: 115,
    padding: "1.25em",
    backgroundColor: odienceColors.nightBlack,
    opacity: visible ? 0.9 : 1,
    borderRadius: "10px",
    transition: "opacity 0.5s ease",
  });

const pollColStyle = css({
  display: "flex",
  gap: "1.25em",
  flexDirection: "column",
  width: "100%",
});

const pollButtonRowStyle = css({
  display: "flex",
  gap: "0.6rem",
  alignItems: "end",
  justifyContent: "end",
  width: "100%",
});

const enterDisplayNameStyle = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  padding: "2vh 1vw",
  color: odienceColors.pureWhite,
  backgroundColor: odienceColors.midnightBlue,
  fontSize: "1em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "13em",
  cursor: "pointer",
});

const closeButtonStyle = css({
  display: "flex",
  alignItems: "top",
  justifyContent: "top",
});

const closeIconStyle = css({ width: "1.5em", height: "1.5em" });
