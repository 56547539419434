import SwipeToExplore from "@/assets/SwipeToExplore.svg";
import { odienceColors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import { promptStyle } from "../EventStream.style";

type MobileStreamPromptProps = {
  handleDownloadApp: () => void;
  handleContinueWeb: () => void;
};

const MobileStreamPrompt = ({
  handleDownloadApp,
  handleContinueWeb,
}: MobileStreamPromptProps) => {
  return (
    <div css={overlayStyle}>
      <div css={promptContainerStyle}>
        <img src={SwipeToExplore} />
        <div css={swipeTextStyle}>Swipe to explore!</div>
      </div>
      <div
        css={[
          promptStyle,
          { "@media (orientation: landscape)": { height: "23%" } },
        ]}
      >
        <div css={promptContentStyle}>
          <div css={promptTextStyle}>
            Get the Odience App for a <b>better experience</b>
          </div>
          <div css={buttonContainerStyle}>
            <button
              type="button"
              css={buttonStyle(odienceColors.crimsonPink)}
              onClick={handleDownloadApp}
            >
              Get The App
            </button>
            <button
              type="button"
              css={buttonStyle(odienceColors.gunmetalGray)}
              onClick={handleContinueWeb}
            >
              Continue on Web
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileStreamPrompt;

const overlayStyle = css({
  display: "flex",
  justifyContent: "center",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  zIndex: 9999,
});

const promptContainerStyle = css({
  position: "absolute",
  top: "27vh",
  fontFamily: "Figtree, sans-serif",
});

const swipeTextStyle = css({
  color: odienceColors.pureWhite,
  fontSize: "1rem",
  fontWeight: "bold",
  marginTop: "1em",
  textAlign: "center",
});

const promptContentStyle = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: odienceColors.obsidianGray,
});

const promptTextStyle = css({
  position: "absolute",
  top: "1.8em",
  fontSize: "0.9rem",
  color: odienceColors.pureWhite,
  "@media (orientation: landscape)": { top: "10px" },
});

const buttonContainerStyle = css({
  display: "flex",
  flexDirection: "row",
  flexFlow: "wrap",
  gap: "2vh",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "1.25em",
  "@media (orientation: landscape)": { gap: "5vh" },
});

const buttonStyle = (backgroundColor: string) =>
  css({
    borderRadius: "20px",
    backgroundColor,
    color: odienceColors.pureWhite,
    height: "6vh",
    border: "none",
    fontWeight: "400",
    width: "12em",
    fontSize: "0.8rem",
    "@media (orientation: landscape)": { height: "10vh" },
  });
