/* eslint-disable @eslint-react/hooks-extra/no-direct-set-state-in-use-effect */
import { discardMessageNotificationAndOverlay } from "@/components/navigation/Navigation";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import { useProfileModal } from "@/pages/profile/utils/ProfileModalContext";
import { paths } from "@/routerPaths";
import { odienceColors } from "@/styles/global.styles";
import { directorUrl, routerUrl } from "@/utils";
import { generateRandomString, isMobile } from "@/utils/helpers/Utils";
import { atoms } from "@/utils/helpers/atoms";
import { ls } from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { ss } from "@/utils/helpers/sessionStorage";
import { useToast } from "@/utils/helpers/toastManager";
import { useCall } from "@/utils/hooks/useCall";

import {
  MediaAlignment,
  MediaLayout,
} from "@/components/chatScreen/chat/typings/moderatorChatbotInfo";
import { LayoutContext } from "@/pages/profile/utils/LayoutContext";
import { updateContactInExistingConversations } from "@/utils/contacts";
import { WebGwContactList } from "@/utils/helpers/WebGwContact";
import { isChatbot } from "@/utils/helpers/chatbots";
import { formatActiveUserCountdown } from "@/utils/helpers/time";
import { useExponentialBackoff } from "@/utils/hooks/useExponentialBackoff";
import useProvisioningModal from "@/utils/hooks/useProvisioningModal";
import useParentIframeUrl from "@/utils/hooks/useUpdateIframeParent";
import { useSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import {
  cleanPhoneNumber,
  isSamePhoneNumber,
} from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { isSocketIOConnected } from "@/utils/network";
import { css } from "@emotion/react";
import { useNetworkState } from "@uidotdev/usehooks";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { use, useCallback, useEffect, useRef, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import { useUnmount } from "usehooks-ts";
import {
  DEFAULT_AVATAR,
  OdienceEvent,
  OdienceUser,
  authenticateDirector,
  isAllowed,
} from "../../../../utils/hooks/useDirectorAuthentication";
import {
  generateEventListPath,
  useOdienceEvents,
} from "../../../../utils/hooks/useOdienceEvents";
import { useOdienceOrganization } from "../../../../utils/hooks/useOdienceOrganization";
import { useUserInactivity } from "../../../../utils/hooks/useUserInactivity";
import GetAppModal from "../../components/GetAppModal";
import LoadingAnimation from "../../components/LoadingAnimation";
import OdiencePreviewMobileHeader from "../../components/OdiencePreviewMobileHeader";
import EventPoll from "./components/EventPoll";
import EventStreamActivityPanel from "./components/EventStreamActivityPanel";
import EventStreamHeader from "./components/EventStreamHeader";
import EventStreamMessageContainer, {
  EventStreamMessageContainerApi,
} from "./components/EventStreamMessageContainer";
import FeedItemsModal from "./components/FeedItemsModal";
import StreamCenter from "./components/StreamCenter";
import VideoWallRequest from "./components/VideoWallRequest";
import {
  CarouselOrientationType,
  FeedItemList,
  MediaPool,
  Message,
  Participant,
  STREAM_TYPE_MAIN,
  STREAM_TYPE_PIP,
  SelectedStreamsType,
  Stream,
  StreamCenterRef,
  StreamInfo,
  StreamInfosType,
  StreamsList,
  TextErrorLoading,
  UserWallReactionsData,
  filterStreamsByAccess,
  getUserPermissions,
} from "./helpers/EventStreamUtils";

type EventStreamProps = { odienceOnly?: boolean };

const SOCKET_RECONNECTION_MAX_RETRY = 3;
const EventStream = ({ odienceOnly = false }: EventStreamProps) => {
  // Hooks & Contexts
  const { openModal, userAvatar, updateUserAvatar, userDisplayName } =
    useProfileModal();
  const { canAcceptOrMakeCall, callWithVideo } = useCall();
  const { showToast, dismissToast } = useToast();
  const { objEvents, isFetching } = useOdienceEvents({ updateUserAvatar });
  const { eventId } = useParams();
  const { embeddedMode } = useOdienceOrganization();
  const network = useNetworkState();
  const navigate = useNavigate();
  const selectedConversationId = useSelectedConversationId();
  const lastGroupId = ss.getLastGroupId();
  const lastEventId = ss.getLastEventId();
  const provisioned = isProvisioned();
  const updateIframeUrl = useParentIframeUrl({
    initialUrl: window.location.href,
    embeddedMode,
  });
  const { hasNavBar } = use(LayoutContext);

  // User Profile
  const [odienceUser, setOdienceUser] = useState<OdienceUser | null>(null);
  const [hasDisplayName, setHasDisplayName] = useState(!!userDisplayName);
  const [odienceAvatar, setOdienceAvatar] = useState<string>(
    userAvatar || DEFAULT_AVATAR
  );
  const profileComplete = !!(hasDisplayName || userDisplayName);

  // Chat & Messaging
  const setChatBotMediaExtraInfo = useSetAtom(
    atoms.odience.chatBotMediaExtraInfo
  );
  const [pendingInitMessages, setPendingInitMessages] = useState<Message[]>([]);
  const [pendingNewMessages, setPendingNewMessages] = useState<Message[]>([]);
  const [pendingDeletedMessages, setPendingDeletesMessages] = useState<
    string[]
  >([]);
  const [messageToSend, setMessageToSend] = useAtom(
    atoms.odience.messageToSend
  );
  const setStreamParticipantsPhoneNumbers = useSetAtom(
    atoms.odience.streamParticipantsPhoneNumbers
  );
  const messagesContainerRef = useRef<EventStreamMessageContainerApi>(null);

  // Participants
  const [participantCount, setParticipantCount] = useState(0);
  const [participantList, setParticipantList] = useState<Participant[]>([]);

  // Feed Items & Media
  const [feedItems, setFeedItems] = useState<FeedItemList>({});
  const [selectedFeedItemId, setSelectedFeedItemId] = useState<string | null>(
    null
  );
  const [isFeedItemsModalOpen, setFeedItemsModalOpen] = useState(false);

  // Carousel
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselOrientation, setCarouselOrientation] =
    useState<CarouselOrientationType>("horizontal");

  // Reactions & Media Pool
  const [reactionData, setReactionData] = useState<UserWallReactionsData>();
  const [, setTotalReactions] = useState<number | null>(null);
  const [mediaPool, setMediaPool] = useState<MediaPool | null>(null);

  // Streaming & Event State
  const [objEvent, setObjEvent] = useState<OdienceEvent | null>(null);
  const [streamDefaultRotation, setStreamDefaultRotation] = useState(0);
  const [showEventEnded, setShowEventEnded] = useState(false);
  const [availableEventStreams, setAvailableEventStreams] = useState<Stream[]>(
    []
  );
  const [noAvailableStreams, setNoAvailableStreams] = useState(false);
  const [selectedStreams, setSelectedStreams] = useState<
    SelectedStreamsType | undefined
  >(undefined);
  const [standbyImage, setStandbyImage] = useState("");
  const [forceStream, setForceStream] = useState(false);
  const [isActiveUser, setIsActiveUser] = useState(true);
  const [activeUserCountdown, setActiveUserCountdown] = useState(120); // 2 minutes
  useUserInactivity(
    setIsActiveUser,
    setActiveUserCountdown,
    isActiveUser,
    activeUserCountdown,
    objEvent!,
    embeddedMode
  );

  const { modal: provisioningModal, open: openProvisioning } =
    useProvisioningModal({
      // TODO- In embedded mode no need to redirect as the screen stays the same without the nav bar
      onSuccessPathRedirect: generatePath(paths.stream, {
        eventId: objEvent?.id || "",
      }),
      fromOdience: true,
    });

  // Streaming Management & Sockets
  const arrStreamsForSockets = useRef<Stream[]>([]);
  const socketRef = useRef<SocketIOClient.Socket | null>(null);
  const streamCenterRef = useRef<StreamCenterRef>(null!);
  const [errorLoading, setErrorLoading] = useState(false);

  // Calls & Video Wall
  const [videoWallRequested, setVideoWallRequested] = useState(false);
  const [frontRowRequested, setFrontRowRequested] = useState(false);
  const callActive = useAtomValue(atoms.calling.callActive);
  const [isVideoWallCall, setIsVideoWallCall] = useAtom(
    atoms.odience.isVideoWallCall
  );
  const [isFrontRowCall, setIsFrontRowCall] = useAtom(
    atoms.odience.isFrontRowCall
  );
  const setFeaturedCaller = useSetAtom(atoms.odience.featuredCaller);

  // Device & Audio Management
  const odienceDevice = useRef<{ id: number; name: string } | undefined>(
    undefined
  );
  const [deviceId, setDeviceId] = useState("");
  const usePreviewFrame = useAtomValue(atoms.odience.doUsePreviewFrame);
  const [mutedByModerator, setMutedByModerator] = useAtom(
    atoms.odience.mutedByModerator
  );
  const callMuted = useAtomValue(atoms.calling.callMuted);
  const [micVolume, setMicVolume] = useAtom(atoms.odience.streamMicVolume);
  const [streamVolume, setStreamVolume] = useAtom(atoms.odience.streamVolume);
  const [lastVolume, setLastVolume] = useState(streamVolume / 100);
  const [previousVolume, setPreviousVolume] = useAtom(
    atoms.odience.previousVolume
  );
  const [isStreamActive, setIsStreamActive] = useState(false);

  // Miscellaneous + Consts
  const [itemsOnList, setItemsOnList] = useState<string[]>([]);
  const [loadingCountdown, setLoadingCountdown] = useState<number | undefined>(
    undefined
  );
  const [getAppModalOpen, setGetAppModalOpen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );
  const isLoggedIn = useAtomValue(atoms.provisioning.isLoggedIn);
  const secondsToStartErrorLoadingCountdown = 10;
  const secondsToReloadIfNoEvent = 10;
  const restream = objEvent?.label === "re-stream";

  useEffect(() => {
    if (!callActive) {
      setLastVolume(streamVolume / 100);
    }
  }, [streamVolume, callActive]);

  useEffect(() => {
    setPreviousVolume(lastVolume * 100);
  }, [lastVolume]);

  const handleOnAddItemToList = (id: string) => {
    if (!itemsOnList.includes(id)) {
      setItemsOnList((previous) => [...previous, id]);
    }
  };

  const handleNavigateBack = async (toEventList = false) => {
    if (streamCenterRef.current?.stopStreams) {
      streamCenterRef.current.stopStreams();
    }

    let navigateTo;
    const provisioned = isProvisioned();
    if (!toEventList && lastGroupId && lastEventId) {
      navigateTo = generatePath(
        provisioned ? paths.details : paths.previewOdienceDetails,
        {
          groupId: lastGroupId,
          eventId: lastEventId,
        }
      );
    } else {
      navigateTo = provisioned ? paths.odience : paths.previewOdience;
    }

    handleResetOdienceCallStates();
    await navigate(navigateTo);
  };

  useEffect(() => {
    updateIframeUrl(window.location.href);
  }, []);

  // Anytime selected conversation changes (chat overlay), we set the media extra info (Images alignment) for chatbots
  useEffect(() => {
    setChatbotMediaExtraInfo();
  }, [selectedConversationId, objEvent?.chatbots]);

  useEffect(() => {
    let counter: number | NodeJS.Timeout;
    if (!objEvent && loadingCountdown !== undefined) {
      if (loadingCountdown > 0) {
        counter = setInterval(() => {
          setLoadingCountdown((previous) => --previous!);
        }, 1000);
      } else {
        window.location.reload();
      }
    }

    return () => {
      if (counter) {
        clearInterval(counter);
      }
    };
  }, [loadingCountdown, objEvent]);

  useEffect(() => {
    setOdienceAvatar(userAvatar);
  }, [userAvatar]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleCheckIfEventIsActive = (
    eventList: OdienceEvent[],
    eventId: string
  ) => {
    const eventExists = eventList.some((event) => event.id === eventId);

    setShowEventEnded(!eventExists);

    if (!eventExists) {
      ss.removeLastEventId();
      ss.removeLastGroupId();
      setSelectedStreams((previous) =>
        previous
          ? {
              ...(previous.main && {
                main: { ...previous.main, isRunning: false },
              }),
              ...(previous.pip && {
                pip: { ...previous.pip, isRunning: false },
              }),
            }
          : undefined
      );
    }
  };

  useEffect(() => {
    // Because use could have been not provisioned before, do not check if there is a current request for fetching the events (previous events are the ones when not provisioned and therefore will miss private events)
    if (eventId && objEvents && !isFetching) {
      handleCheckIfEventIsActive(objEvents, eventId);
    }
  }, [objEvents, isFetching]);

  const handleActionToParticipate = () => {
    if (isProvisioned()) {
      openModal();
    } else {
      openProvisioning();
    }
  };

  const toggleGetAppModal = () => {
    setGetAppModalOpen(!getAppModalOpen);
  };

  const handleErrorLoading = () => {
    setErrorLoading(!errorLoading);
  };

  const handleTotalParticipantCount = (data: { count: number }) => {
    setParticipantCount(data.count);
  };

  const handleParticipantJoined = (data: { list: any[] }) => {
    const joinedUsers = data.list;
    const userId = (ls.getUser() ?? "").replaceAll("+", "");
    const filteredJoinedUsers = joinedUsers.filter(
      (user) => user.id !== userId
    );

    setParticipantList((prevParticipants) => {
      const res = sortUsersByLocalThenName([
        ...prevParticipants,
        ...filteredJoinedUsers,
      ]);
      updateStreamParticipantsPhoneNumbers(res);
      return res;
    });
  };

  const handleParticipantInfoUpdate = (data: Participant) => {
    const updateSIP = data.sip.replace("+", "");
    setParticipantList((prevParticipants) => {
      const res = sortUsersByLocalThenName(
        prevParticipants
          .filter(
            (participant) => updateSIP !== participant.sip.replace("+", "")
          )
          .concat([data])
      );
      updateStreamParticipantsPhoneNumbers(res);
      return res;
    });
  };

  const handleParticipantLeft = (data: { list: any[] }) => {
    const leavingSIPs = data.list.map((user) => user.sip);
    setParticipantList((prevParticipants) => {
      const res = sortUsersByLocalThenName(
        prevParticipants.filter(
          (participant) => !leavingSIPs.includes(participant.sip)
        )
      );
      updateStreamParticipantsPhoneNumbers(res);
      return res;
    });
  };

  const updateStreamParticipantsPhoneNumbers = (
    participants: Participant[]
  ) => {
    setStreamParticipantsPhoneNumbers(
      participants.map((participant) => cleanPhoneNumber(participant.sip))
    );
  };

  const sortUsersByLocalThenName = (participantList: Participant[]) => {
    const userId = (ls.getUser() ?? "").replaceAll("+", "");
    const userIds = {};

    return participantList
      .filter((current) => {
        return userIds[current.id] ? false : (userIds[current.id] = true);
      })
      .sort((a, b) => {
        if (a.id === userId) {
          return -1;
        } else if (b.id === userId) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
  };

  const handleParticipantList = (data: { list: Participant[] }) => {
    const res = sortUsersByLocalThenName(data.list);
    setParticipantList(res);
    updateStreamParticipantsPhoneNumbers(res);
  };

  const handleOdienceAvatarError = () => {
    setOdienceAvatar(DEFAULT_AVATAR);
  };

  const findAndConnectStream = async (data: {
    value: boolean;
    streamId: string;
    position?: StreamInfosType["position"];
    type: StreamInfosType["type"];
  }) => {
    if (!data.value && data.type === STREAM_TYPE_PIP) {
      setSelectedStreams((previous) => previous && { main: previous.main });
      return;
    }

    if (arrStreamsForSockets.current.length === 0) {
      console.error("findAndConnectStream no streams loaded yet");
      return;
    }

    const stream = arrStreamsForSockets.current.find(
      (x) => x.id === data.streamId
    );

    if (stream && stream.info.urls.length > 0) {
      await connectToStream(stream.info, data.type, data.position);
      if (stream.info.urls[0].status) {
        const isRunning = JSON.parse(stream.info.urls[0].status).is_running;
        setSelectedStreams(
          (previous) =>
            previous && {
              ...previous,
              [data.type === STREAM_TYPE_MAIN ? "main" : "pip"]: {
                ...previous[data.type === STREAM_TYPE_MAIN ? "main" : "pip"],
                isRunning,
              },
            }
        );
      }
    } else {
      console.log(
        `findAndConnectStream no streams found for id ${data.streamId}`
      );
    }
  };

  const updateStreamStatus = (selectedStream: Stream) => {
    if (selectedStream.info.urls[0].status) {
      const isRunning = JSON.parse(
        selectedStream.info.urls[0].status
      ).is_running;
      setSelectedStreams((previous) =>
        previous
          ? {
              ...previous,
              ...(previous.main && { main: { ...previous.main, isRunning } }),
            }
          : undefined
      );
    }
  };

  const handleStreamsList = async (streams: StreamsList) => {
    if (streams.settings.media_pool) {
      setMediaPool(streams.settings.media_pool as MediaPool);
    }
    setAvailableEventStreams(streams.list);

    const { canViewPremiumStreams, canViewStaffStreams } = getUserPermissions(
      odienceUser,
      objEvent
    );

    const filteredStreams = filterStreamsByAccess(
      streams.list,
      !!canViewPremiumStreams,
      !!canViewStaffStreams
    );
    const provisioned = isProvisioned();

    if (ls.getDirectorToken() === "" || odienceOnly) {
      if (!provisioned) {
        const mainStream =
          filteredStreams.find((stream) => stream.selected) ||
          filteredStreams[0];

        const pipStream = streams.list.find(
          (current) =>
            current.info.type === STREAM_TYPE_PIP &&
            current.info.position &&
            current.info.id !== mainStream?.id
        );

        setSelectedStreams({
          ...(mainStream
            ? {
                main: {
                  id: mainStream.id,
                  serverId: mainStream.info.urls[0].serverId,
                  is360Stream: mainStream.info.is_360,
                  isRunning: true,
                  type: STREAM_TYPE_MAIN,
                },
              }
            : {}),
          ...(pipStream
            ? {
                pip: {
                  id: pipStream.id,
                  serverId: pipStream.info.urls[0].serverId,
                  is360Stream: false,
                  isRunning: true,
                  type: STREAM_TYPE_PIP,
                  position: pipStream.info.position,
                },
              }
            : {}),
        });

        if (mainStream) {
          updateStreamStatus(mainStream);
        }
      }
    }

    arrStreamsForSockets.current = streams.list;

    let pipStream;

    const mainStream =
      filteredStreams.find((current) => current.selected) ||
      filteredStreams.find((current) => current.info.type !== STREAM_TYPE_PIP);

    if (provisioned) {
      const connectAndSetStream = async (stream, type) => {
        setStreamDefaultRotation(stream.info.rotation);
        await connectToStream(stream.info, type);
        if (stream.info.urls[0].status) {
          const isRunning = JSON.parse(stream.info.urls[0].status).is_running;
          setSelectedStreams(
            (previous) =>
              previous && {
                ...previous,
                [type === STREAM_TYPE_MAIN ? "main" : "pip"]: {
                  ...previous[type === STREAM_TYPE_MAIN ? "main" : "pip"],
                  isRunning,
                },
              }
          );
        }
      };

      if (mainStream) {
        await connectAndSetStream(mainStream, STREAM_TYPE_MAIN);

        if (pipStream && pipStream.info.active) {
          await connectAndSetStream(pipStream, STREAM_TYPE_PIP);
        }
      } else {
        setSelectedStreams(
          (previous) =>
            previous && {
              ...previous,
              main: undefined,
              ...(!pipStream && { pip: undefined }),
            }
        );
      }
    }

    setNoAvailableStreams(filteredStreams.length === 0);
    setStandbyImage(streams.settings.standby_media.url);
  };

  useEffect(() => {
    console.log(
      "Updated Selected Streams and Standby",
      selectedStreams,
      standbyImage
    );
  }, [selectedStreams, standbyImage]);

  const connectToStream = async (
    streamInfo: StreamInfo,
    type: StreamInfosType["type"],
    position?: StreamInfosType["position"]
  ) => {
    const url = streamInfo.urls[0].url;
    const apiUrl = url;
    const headers =
      ls.getDirectorToken() === ""
        ? undefined
        : {
            headers: {
              Authorization: `Bearer ${ls.getDirectorToken()}`,
            },
          };

    try {
      const response = await fetch(apiUrl, headers);
      if (response.status === 302 || response.status === 401) return;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const data = (await response.json()) as { url: string };
        if (data) {
          const arrUrl = data.url.split("live/")[1];
          const streamIdentifier = arrUrl.split("?")[0];
          setSelectedStreams((previous) => ({
            ...previous,
            [type === STREAM_TYPE_MAIN ? "main" : "pip"]: {
              id: streamInfo.id,
              serverId: streamIdentifier,
              is360Stream: streamInfo.is_360,
              type,
              isRunning: true,
              position: position || streamInfo.position,
            },
          }));
        }
      }
    } catch (error) {
      console.error(`Error fetching data for ${url}: ${error}`);
    }
  };

  const sendMessage = () => {
    if (messageToSend.trim() !== "") {
      if (!isSocketIOConnected(socketRef)) {
        return;
      }

      socketRef.current.emit("MessageNew", { content: messageToSend });
      setMessageToSend("");
    }
  };

  const handleSendReaction = (key: string) => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("UserWallReaction", {
      reaction: key,
    });
  };

  const setChatbotMediaExtraInfo = () => {
    if (
      !objEvent ||
      !selectedConversationId ||
      !isChatbot(selectedConversationId)
    ) {
      return;
    }

    const bot = objEvent.chatbots.find((chatbot) =>
      chatbot.bot_id.includes(selectedConversationId)
    );
    if (bot) {
      setChatBotMediaExtraInfo(
        bot.chatbot_image_alignment && bot.chatbot_image_style
          ? {
              mediaAlignment: bot.chatbot_image_alignment as MediaAlignment,
              mediaLayout: bot.chatbot_image_style as MediaLayout,
            }
          : undefined
      );
    }
  };

  const handleChatbotsList = (chatbotsList) => {
    if (eventId === chatbotsList.eventId) {
      setObjEvent((prevEvent) => {
        return (
          prevEvent && {
            ...prevEvent,
            chatbots: chatbotsList.list,
          }
        );
      });
    }
  };

  const handleUpdateEventInfo = (event: OdienceEvent) => {
    // For now only update name and chat on/off
    setObjEvent((prev) => {
      return (
        prev && {
          ...prev,
          brand: {
            ...prev.brand,
            brand_logo_padding: event.brand?.brand_logo_padding,
            brand_image_url: event.brand?.brand_image_url,
            brand_background_image_url: event.brand?.brand_background_image_url,
            brand_ad_image_url: event.brand?.brand_ad_image_url,
            brand_background_opacity: event.brand?.brand_background_opacity,
            brand_text_color: event.brand?.brand_text_color,
            brand_background_color: event.brand?.brand_background_color,
            brand_title: event.brand?.brand_title,
            brand_subtitle: event.brand?.brand_subtitle,
          },
          name: event.name,
          settings: {
            ...prev.settings,
            event_feature_chat: event.settings.event_feature_chat,
          },
        }
      );
    });
  };

  const handleEventsListUpdateAvailable = (eventId: string | undefined) => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("GetEventInfo", { eventId: eventId });
  };

  const handleOnConnectionChange = (poorConnection: boolean) => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("DeviceConnection", {
      poorConnection: poorConnection,
    });
  };

  const handleGetEventInfo = async (event: OdienceEvent) => {
    setLoadingCountdown(undefined);

    if (!isAllowed(event) && isProvisioned()) {
      await navigate(paths.odience);
    }

    if (event.event_ended) {
      handleEventEnded(event);
      return;
    }

    setCarouselOpen(event.mini_carousel_open);
    setCarouselOrientation(
      event.mini_carousel_orientation as CarouselOrientationType
    );
    setObjEvent(event);
    setForceStream(event.live_stream_switching);
  };

  const handleOpenFeedModal = (itemId: string) => {
    if (itemId && Object.prototype.hasOwnProperty.call(feedItems, itemId)) {
      setSelectedFeedItemId(itemId);
    } else {
      const keys = Object.keys(feedItems);
      if (keys.length > 0) {
        setSelectedFeedItemId(keys[0]);
      }
    }
    setFeedItemsModalOpen(true);
  };

  const handleShowMiniCarousel = (input: {
    orientation: string;
    value: boolean;
  }) => {
    setCarouselOpen(input.value);
    setCarouselOrientation(input.orientation as CarouselOrientationType);
  };

  const selectMiniItem = (strFeedId = "") => {
    if (!carouselOpen) return;
    dismissToast?.();
    const miniFeedItems = document.querySelectorAll(".miniFeedItem");
    for (let i = 0; i < miniFeedItems.length; i++) {
      const item = miniFeedItems[i];
      item.classList.toggle("selected", item.classList.contains(strFeedId));
    }
    const keys = Object.keys(feedItems);
    if (!strFeedId && keys.length > 0) {
      strFeedId = keys[0];
    }
    setSelectedFeedItemId(strFeedId);
    handleOpenFeedModal(strFeedId);
  };

  const handleEventEnded = (event: OdienceEvent) => {
    if (event.id === eventId) {
      setShowEventEnded(true);
      ss.removeLastEventId();
      ss.removeLastGroupId();

      // TODO - we should discard notification and overlay only for bots inside the event
      discardMessageNotificationAndOverlay();
      disconnectSocket();
      streamCenterRef.current?.stopStreams();
    }
  };

  const disconnectSocket = () => {
    console.log("Disconnecting from socket");
    socketRef.current?.removeAllListeners();
    socketRef.current?.disconnect();
    socketRef.current = null;
  };

  const handleUsersWallReactions = (data: UserWallReactionsData) => {
    setReactionData({ ...data, timestamp: new Date().getTime() });
    const Over1000UserWallReactions = 1000;
    const totalReactions = data.total;

    if (totalReactions > Over1000UserWallReactions) {
      setTimeout(
        () => {
          setTotalReactions(totalReactions);
        },
        500 * (data.users.length / 2)
      );
    }
  };

  const fetchItems = async () => {
    try {
      const apiUrl = `${directorUrl}/mobile/chatbotFeedJson/${eventId}`;
      const response = await fetch(apiUrl);
      const items = await response.json();
      setFeedItems(items);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleResetOdienceCallStates = () => {
    setIsVideoWallCall(false);
    setVideoWallRequested(false);
    setIsFrontRowCall(false);
    setFrontRowRequested(false);
    setFeaturedCaller(false);
    setDeviceId("");
    setMutedByModerator(false);
    setStreamVolume(previousVolume);
  };

  const handleVideoWallBackNavigation = useCallback(() => {
    const confirmLeave = window.confirm(
      "Are you sure you want to go back? You are currently on the video wall."
    );

    if (confirmLeave) {
      handleResetOdienceCallStates();
      window.history.back();
    } else {
      window.history.forward();
    }
  }, []);

  useEffect(() => {
    if (!callActive) return;
    window.history.pushState(null, "", window.location.href);

    window.addEventListener("popstate", handleVideoWallBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleVideoWallBackNavigation);
    };
  }, [handleVideoWallBackNavigation, callActive]);

  const handleVideoWallLinked = (data) => {
    if (odienceDevice.current && data.deviceId === odienceDevice.current.id) {
      WebGwContactList.saveTransientContact(
        data.deviceSip,
        odienceDevice.current.name,
        updateContactInExistingConversations
      );
    }

    if (canAcceptOrMakeCall) callWithVideo(data.deviceSip);
    setDeviceId(data.deviceId);
  };

  const handleDeviceResponse = (accept: boolean, isVideoWallCall: boolean) => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("DeviceResponse", { content: accept });
    setLastVolume(streamVolume / 100);

    if (isVideoWallCall) {
      setVideoWallRequested(false);
      setIsVideoWallCall(accept);
    } else {
      setFrontRowRequested(false);
      setIsFrontRowCall(accept);
    }
  };

  const handleAcceptOdienceCallRequest = (isVideoWallCall: boolean) => {
    setLastVolume(streamVolume / 100);
    handleDeviceResponse(true, isVideoWallCall);
  };

  const handleDeclineOdienceCallRequest = (isVideoWallCall: boolean) => {
    handleDeviceResponse(false, isVideoWallCall);
  };

  const handleDeviceRequest = (request) => {
    switch (request.type) {
      case "front":
        setFrontRowRequested(!request.cancel && canAcceptOrMakeCall);
        odienceDevice.current = { id: request.id, name: request.name };

        if (request.cancel) {
          setIsFrontRowCall(false);
          showToast(`Front Row session cancelled`);
        }
        break;

      case "live":
        setVideoWallRequested(!request.cancel && canAcceptOrMakeCall);

        if (request.cancel) {
          setIsVideoWallCall(false);
          showToast(`Video Wall session cancelled`);
        }
        break;
    }
  };

  const handleLeaveOdienceCall = () => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }
    setStreamVolume(previousVolume);
    socketRef.current.emit("DeviceEnd", { deviceId });
    setDeviceId("");
    if (callActive && (isFrontRowCall || isVideoWallCall)) {
      showToast(
        `${isFrontRowCall ? "Front Row" : "Video Wall"} session terminated`
      );
    }
  };

  const sendPreviewFrames = () => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("UserPreviewFrame", { content: usePreviewFrame });
  };

  const handleActiveFeaturedCaller = (data) => {
    if (isSamePhoneNumber(data.sip, ls.getUser())) {
      setFeaturedCaller(true);
      showToast("The moderator has elevated you to the featured caller");
    }
  };

  const handleDisableFeaturedCaller = (data) => {
    if (isSamePhoneNumber(data.sip, ls.getUser())) {
      setFeaturedCaller(false);
      // This event is often got after leaving the call, make sure to check call active to not display the toast if not
      if (callActive) {
        showToast("The moderator has removed you as the featured caller");
      }
    }
  };

  const handleMicVolume = () => {
    setMicVolume(mutedByModerator || callMuted ? 0 : 100);
  };

  const sendStreamVolumeDetails = () => {
    if (!isSocketIOConnected(socketRef)) {
      return;
    }

    socketRef.current.emit("UpdateUserVolume", {
      stream: streamVolume,
      call: micVolume,
    });
  };

  const handleForceMuteDevice = (data) => {
    setMutedByModerator(data.value);
  };

  useEffect(() => {
    console.log("Media pool", mediaPool);
  }, [mediaPool]);

  useEffect(() => {
    if (callActive) {
      if (isVideoWallCall) {
        showToast(
          mutedByModerator
            ? "The Moderator has disabled your Video Wall Microphone."
            : "The Moderator has Enabled your Video Wall Microphone."
        );
      } else if (isFrontRowCall) {
        showToast(
          "The Moderator has turned on your microphone. If you prefer, you can turn if off."
        );
      }
    }
  }, [callActive, mutedByModerator]);

  useEffect(() => {
    if (!isVideoWallCall && !isFrontRowCall) {
      handleLeaveOdienceCall();
    }
  }, [isVideoWallCall, isFrontRowCall, deviceId]);

  useEffect(() => {
    if (usePreviewFrame && isVideoWallCall) {
      sendPreviewFrames();
    }
  }, [usePreviewFrame, isVideoWallCall]);

  useEffect(() => {
    if (isVideoWallCall || isFrontRowCall) {
      sendStreamVolumeDetails();
    }
  }, [micVolume, streamVolume]);

  useEffect(() => {
    handleMicVolume();
  }, [mutedByModerator, callMuted]);

  useEffect(() => {
    if (showEventEnded && restream) return;
    void connectSocket();

    return () => {
      disconnectSocket();
      stopReconnectSocketRetry();
    };
  }, [odienceUser?.account_type, isLoggedIn, network.online]);

  /**
   * Because message container can be non initialized at the time messages arrived, we use a isInit flag instead of .current (re-rendering this component will always trigger this useEffect)
   * TODO: best would be to always have component ref messagesContainerRef rendered to avoid this useEffect. Right now the message component is only included after getting event info from socket.
   */
  useEffect(() => {
    if (
      !messagesContainerRef.current?.isInit ||
      pendingInitMessages.length === 0
    ) {
      return;
    }

    console.log("Pending init messages");

    messagesContainerRef.current.init(pendingInitMessages);
  }, [messagesContainerRef.current?.isInit, pendingInitMessages]);

  // See comment above
  useEffect(() => {
    if (
      !messagesContainerRef.current?.isInit ||
      pendingNewMessages.length === 0
    ) {
      return;
    }

    console.log("Pending new messages");

    messagesContainerRef.current.addMessages(pendingNewMessages);
  }, [messagesContainerRef.current?.isInit, pendingNewMessages]);

  // See comment above
  useEffect(() => {
    if (
      !messagesContainerRef.current?.isInit ||
      pendingDeletedMessages.length === 0
    ) {
      return;
    }

    console.log("Pending new messages");

    messagesContainerRef.current.removeMessages(pendingDeletedMessages);
  }, [messagesContainerRef.current?.isInit, pendingDeletedMessages]);

  useUnmount(() => {
    disconnectSocket();
    stopReconnectSocketRetry();
    setStreamParticipantsPhoneNumbers([]);
  });

  const connectSocket = async () => {
    if (!network.online || (showEventEnded && restream)) {
      return;
    }
    const provisioned = isProvisioned();

    if (provisioned && !isLoggedIn) {
      return;
    }

    void fetchItems();
    let userId;

    if (provisioned) {
      const authenticatedUser = (await authenticateDirector()) as OdienceUser;
      setOdienceUser(authenticatedUser);
      if (authenticatedUser.avatar != null) {
        setOdienceAvatar(authenticatedUser.avatar);
      }
      userId = authenticatedUser.msisdn;
      setHasDisplayName(!!authenticatedUser.name);
    }

    disconnectSocket();
    setPendingInitMessages([]);
    setPendingNewMessages([]);
    setPendingDeletesMessages([]);
    const url = `${routerUrl}/${eventId}`;

    const option = provisioned
      ? {
          transports: ["websocket"],
          query: { user_id: userId, token: ls.getDirectorToken() },
        }
      : {
          transports: ["websocket"],
          query: {
            user_id: `guest-${generateRandomString(15)}`,
          },
        };

    console.log("Creating new socket connection with ", option);

    socketRef.current = io(url, { ...option, reconnection: false });
    socketRef.current.on("Connected", () => {
      console.log("Connected to", socketRef.current);
      stopReconnectSocketRetry();

      // Sometimes director does not respond with the event info, if no event received in secondsToStartErrorLoadingCountdown, launch a timer to reload the page after secondsToReloadIfNoEvent
      setTimeout(() => {
        if (!objEvent) {
          setLoadingCountdown(secondsToReloadIfNoEvent);
        }
      }, secondsToStartErrorLoadingCountdown * 1000);
    });

    socketRef.current.on("MessagePublished", (message: Message) => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.addMessages([message]);
      } else {
        setPendingNewMessages((prev) => [...prev, message]);
      }
    });
    socketRef.current.on("MessagesList", (messages: { list: Message[] }) => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.init(messages.list);
      } else {
        setPendingInitMessages(messages.list);
      }
    });
    socketRef.current.on("MessageDeleted", (message) => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.removeMessages([message.id]);
      } else {
        setPendingDeletesMessages((prev) => [...prev, message.id]);
      }
    });
    socketRef.current.on("StreamsList", handleStreamsList);
    socketRef.current.on("UpdatePictureInPicture", (data) => {
      if (!isProvisioned()) {
        if (!data.value) {
          setSelectedStreams((previous) => previous && { main: previous.main });
          return;
        }

        const pipStream = arrStreamsForSockets.current.find(
          (x) => x.id === data.streamId
        );

        if (pipStream) {
          setSelectedStreams((previous) => ({
            ...previous,
            pip: {
              ...(previous?.pip || {
                ...pipStream,
                id: pipStream.id,
                serverId: pipStream.info.urls[0].serverId,
                is360Stream: false,
                isRunning: true,
                type: STREAM_TYPE_PIP,
              }),
              position: data.position,
            },
          }));
        }
      } else {
        void findAndConnectStream({ ...data, type: STREAM_TYPE_PIP });
      }
    });
    socketRef.current.on("UpdateLiveStreamSwitching", (data) =>
      setForceStream(data.value)
    );
    socketRef.current.on("ForceStream", async (data) => {
      if (!isProvisioned()) {
        const newStream = arrStreamsForSockets.current.find(
          (stream) => stream.id === data.streamId
        );

        if (newStream) {
          setSelectedStreams((previous) => ({
            ...previous,
            main: {
              id: newStream.id,
              serverId: newStream.info.urls[0].serverId,
              is360Stream: newStream.info.is_360,
              isRunning: true,
              type: STREAM_TYPE_MAIN,
            },
          }));

          updateStreamStatus(newStream);
        }
      } else {
        await findAndConnectStream({
          ...data,
          value: true,
          type: STREAM_TYPE_MAIN,
        });
      }
    });
    socketRef.current.on("EventsListUpdateAvailable", () =>
      handleEventsListUpdateAvailable(eventId)
    );
    socketRef.current.on("EventInfo", (event) => handleGetEventInfo(event));
    socketRef.current.on("EventUpdated", (event) =>
      handleUpdateEventInfo(event)
    );
    socketRef.current.on("ChatbotsList", (chatbotsList) =>
      handleChatbotsList(chatbotsList)
    );
    socketRef.current.on(
      "ShowMiniCarousel",
      (input: { orientation: string; value: boolean }) =>
        handleShowMiniCarousel(input)
    );
    socketRef.current.on("UsersLeft", (data) => handleParticipantLeft(data));
    socketRef.current.on("UserInfoUpdate", (data) =>
      handleParticipantInfoUpdate(data)
    );
    socketRef.current.on("UsersJoined", (data) =>
      handleParticipantJoined(data)
    );
    socketRef.current.on("UsersList", (data) => handleParticipantList(data));
    socketRef.current.on("UsersCountChanged", handleTotalParticipantCount);
    socketRef.current.on("UsersWallReactions", handleUsersWallReactions);
    socketRef.current.on("DeviceRequest", (data) => handleDeviceRequest(data));
    socketRef.current.on("ForceMuteDevice", (data) =>
      handleForceMuteDevice(data)
    );
    socketRef.current.on("DeviceUnlinkAlert", () => {
      setIsVideoWallCall(false);
      setIsFrontRowCall(false);
      setFeaturedCaller(false);
    });
    socketRef.current.on("ShowFeaturedCaller", (data) =>
      handleActiveFeaturedCaller(data)
    );
    socketRef.current.on("HideFeaturedCaller", (data) =>
      handleDisableFeaturedCaller(data)
    );
    socketRef.current.on("EventEnded", () => {
      setShowEventEnded(true);
      handleEventEnded(objEvent!);
    });
    socketRef.current.on("CatalogFeedUpdated", fetchItems);
    socketRef.current.on("clientError", (error) => {
      console.error("Socket client error:", error);
    });
    socketRef.current.on("DeviceLinked", handleVideoWallLinked);
    if (isVideoWallCall) {
      sendPreviewFrames();
    }

    socketRef.current.on("AccessDenied", async () => {
      await handleNavigateBack(true);
    });
    socketRef.current.on("EventNotFound", async () => {
      await handleNavigateBack(true);
    });
    socketRef.current.on("UpdateMediaPool", (data: MediaPool) => {
      setMediaPool(data);
    });

    socketRef.current.on("connect_timeout", (error) => {
      if (showEventEnded) {
        console.error("Socket connect timeout: ", error);
      } else {
        tryReconnectSocket();
      }
    });

    socketRef.current.on("connect_error", (error) => {
      if (showEventEnded) {
        console.error("Socket connect error:", error);
      } else {
        console.error("Socket connect error:", error);
        tryReconnectSocket();
      }
    });

    socketRef.current.on("disconnect", (error) => {
      if (showEventEnded) {
        console.error("Socket disconnected: ", error);
      } else {
        console.error("Socket disconnected: ", error);
        tryReconnectSocket();
      }
    });

    socketRef.current.on("error", (error) => {
      if (showEventEnded) {
        console.error("Socket disconnected: ", error);
      } else {
        console.error("Socket disconnected: ", error);
        tryReconnectSocket();
      }
    });
  };

  const tryReconnectSocket = () => {
    if (retryCountReached) {
      void handleNavigateBack(true);
    } else {
      reconnectSocket();
    }
  };

  const {
    run: reconnectSocket,
    stop: stopReconnectSocketRetry,
    retryCountReached,
  } = useExponentialBackoff(connectSocket, SOCKET_RECONNECTION_MAX_RETRY);

  const backUi = (
    <button
      type="button"
      id="backToList"
      onClick={() => handleNavigateBack()}
      css={button}
    >
      <img
        src="/odience/web_client/Exit.svg"
        alt="Back to EventList"
        css={buttonImage}
      />
    </button>
  );

  return (
    <>
      <style>
        {`
        html, body {
          overflow: hidden;
        }
      `}
      </style>
      {objEvent ? (
        <>
          {!showEventEnded && isFeedItemsModalOpen && (
            <FeedItemsModal
              feedItems={feedItems}
              selectedFeedId={selectedFeedItemId}
              show={isFeedItemsModalOpen}
              closeModal={() => setFeedItemsModalOpen(false)}
              selectMiniItem={selectMiniItem}
              chatbotList={objEvent?.chatbots}
              handleActionToParticipate={handleActionToParticipate}
              event={objEvent}
              itemsOnList={itemsOnList}
              onAddItemToList={handleOnAddItemToList}
            />
          )}
          <div
            css={[
              container,
              {
                paddingTop:
                  provisioned && !embeddedMode
                    ? undefined
                    : embeddedMode || isMobile()
                      ? undefined
                      : "5vh",

                height: hasNavBar ? "90vh" : "100vh",
              },
            ]}
          >
            {!isMobile() ? (
              <EventStreamHeader
                odienceAvatar={odienceAvatar}
                handleOdienceAvatarError={handleOdienceAvatarError}
                handleOpenEditProfile={openModal}
                event={objEvent}
                selectedStreamId={selectedStreams?.main?.id}
                is360Stream={selectedStreams?.main?.is360Stream}
                availableEventStreams={availableEventStreams}
                onNavigateBack={handleNavigateBack}
                resetOdienceCallStates={handleResetOdienceCallStates}
                callActive={callActive}
                onStreamChange={setSelectedStreams}
                hideStreamSelection={forceStream}
                updateStreamStatus={updateStreamStatus}
                odienceUser={odienceUser}
                onShowProvisioning={openProvisioning}
              />
            ) : (
              !isLandscape && (
                <OdiencePreviewMobileHeader
                  page={"stream"}
                  participantCount={participantCount}
                />
              )
            )}
            <div
              css={{
                height: isMobile() ? "100vh" : "calc(100% - 10rem)",
                width: "100%",
                display: "flex",
                flexDirection: isMobile() ? "column" : "row",
                justifyContent: "center",
                gap: "1.5em",
                overflow: "hidden",
              }}
            >
              {!showEventEnded && provisioned && (
                <EventPoll
                  socketRef={socketRef}
                  objEvent={objEvent}
                  eventId={eventId}
                  profileComplete={profileComplete}
                  onActionToParticipate={handleActionToParticipate}
                />
              )}
              {(videoWallRequested || frontRowRequested) && provisioned && (
                <VideoWallRequest
                  profileComplete={profileComplete}
                  onActionToParticipate={handleActionToParticipate}
                  onActionToAccept={() =>
                    handleAcceptOdienceCallRequest(videoWallRequested)
                  }
                  onActionToDecline={() =>
                    handleDeclineOdienceCallRequest(videoWallRequested)
                  }
                  type={videoWallRequested ? "videoWall" : "frontRow"}
                />
              )}
              {!showEventEnded && (
                <StreamCenter
                  openFeedModal={handleOpenFeedModal}
                  selectedStreams={selectedStreams}
                  feedItems={feedItems}
                  event={objEvent}
                  ref={streamCenterRef}
                  carouselOpen={carouselOpen}
                  carouselOrientation={carouselOrientation}
                  selectMiniItem={selectMiniItem}
                  selectedMiniItemId={selectedFeedItemId}
                  reactionData={reactionData}
                  isLandscape={isLandscape}
                  errorLoading={errorLoading}
                  handleErrorLoading={handleErrorLoading}
                  standbyImage={standbyImage}
                  streamDefaultRotation={streamDefaultRotation}
                  onStreamActive={setIsStreamActive}
                  isStreamActive={isStreamActive}
                  mediaPool={mediaPool}
                  onConnectionChange={handleOnConnectionChange}
                  lastVolume={lastVolume}
                  setLastVolume={setLastVolume}
                />
              )}

              {!showEventEnded && !isMobile() && (
                <EventStreamActivityPanel
                  event={objEvent}
                  messagesContainerRef={messagesContainerRef}
                  participantCount={participantCount}
                  participantList={participantList}
                  profileComplete={profileComplete}
                  handleActionToParticipate={handleActionToParticipate}
                  sendMessage={sendMessage}
                  sendReaction={handleSendReaction}
                  isStreamActive={isStreamActive}
                />
              )}
              {!showEventEnded && isMobile() && !isLandscape && (
                <div
                  css={{
                    position: isLandscape ? "relative" : undefined,
                    height: isLandscape ? "100%" : "45%",
                    width: isLandscape ? "45%" : "100vw",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    css={{
                      flex: 1,
                      width: "100%",
                    }}
                  >
                    <EventStreamMessageContainer
                      enabled={objEvent.settings.event_feature_chat}
                      ref={messagesContainerRef}
                      profileComplete={profileComplete}
                      handleActionToParticipate={handleActionToParticipate}
                      sendMessage={sendMessage}
                      sendReaction={handleSendReaction}
                      isStreamActive={isStreamActive}
                    />
                  </div>
                </div>
              )}
            </div>
            {showEventEnded && !restream && (
              <ConfirmationPopup
                title={"Event has ended"}
                confirmationMessage="Thank you for participating. Join us again next time or explore the Events List for more events."
                handleAction={() =>
                  navigate(generateEventListPath(objEvent, embeddedMode))
                }
                primaryButtonText="Return to Event List"
                togglePopup={() =>
                  navigate(generateEventListPath(objEvent, embeddedMode))
                }
                closeButtonActive={false}
                secondaryButtonActive={false}
              />
            )}
            {showEventEnded && restream && (
              <ConfirmationPopup
                title={"Rejoin Stream"}
                confirmationMessage="This event have has ended. Rejoin Stream?"
                handleAction={() => {
                  tryReconnectSocket();
                  setShowEventEnded(false);
                }}
                primaryButtonText="Yes"
                togglePopup={() =>
                  navigate(generateEventListPath(objEvent, embeddedMode))
                }
                closeButtonActive={false}
                secondaryButtonActive={true}
                secondaryButtonText="No"
              />
            )}
            {noAvailableStreams && (
              <ConfirmationPopup
                title={"No Streams Available"}
                confirmationMessage="Unfortunately, there are no free streams available for this event. Please try again later."
                handleAction={() =>
                  navigate(generateEventListPath(objEvent, embeddedMode))
                }
                primaryButtonText="Return to Event List"
                togglePopup={() => setNoAvailableStreams(false)}
                closeButtonActive={true}
                secondaryButtonActive={false}
              />
            )}

            {!isActiveUser && (
              <ConfirmationPopup
                title="Are you still there?"
                confirmationMessage={
                  <>
                    It looks like you've been inactive for the past 30 minutes.
                    You will be disconnected in{" "}
                    <span
                      style={{
                        width: "4ch",
                        display: "inline-block",
                        textAlign: "center",
                      }}
                    >
                      {formatActiveUserCountdown(activeUserCountdown)}
                    </span>{" "}
                    unless you confirm your presence.
                  </>
                }
                handleAction={() => setIsActiveUser(true)}
                primaryButtonText="Yes, I'm here"
                togglePopup={() => setIsActiveUser(true)}
                closeButtonActive={false}
                secondaryButtonActive={false}
              />
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            position: "relative",
            width: provisioned ? "100%" : "100vw",
            height: provisioned ? "100%" : "100vh",
          }}
        >
          <div css={BackUIstyle}>{backUi}</div>
          <LoadingAnimation title={"LOADING EVENT"}>
            <TextErrorLoading counter={loadingCountdown} />
          </LoadingAnimation>
        </div>
      )}

      {getAppModalOpen && (
        <GetAppModal
          show={getAppModalOpen}
          onCloseModal={toggleGetAppModal}
          objEvent={null}
        />
      )}
      {provisioningModal}
    </>
  );
};

export default EventStream;

const container = css({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  borderRadius: "20px",
  width: "100vw",
  alignItems: "center",
  backgroundColor: isMobile() ? "rgba(0, 0, 0)" : "#111",
  transition: "all .8s ease",
  overflow: "hidden",
  fontFamily: "Figtree, sans-serif",
  position: "relative",
});

const BackUIstyle = css({
  color: odienceColors.pureWhite,
  borderRadius: "20px",
  height: "4em",
  backgroundColor: odienceColors.onyxGray,
  display: "flex",
  gap: "2em",
  flexShrink: 0,
  alignItems: "center",
  transition: "all .2s ease",
  width: "fit-content",
  padding: "3em",
  position: "absolute",
});

const button = css({
  width: "2em",
  height: "2em",
});
const buttonImage = css({
  height: "2em",
  width: "2em",
  objectFit: "cover",
});
