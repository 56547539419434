import { odienceColors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  leftRight,
  reactionAnimation,
  reactionAvatarAnimation,
  reactionTotalAnimation,
} from "../EventStream.style";
import {
  UserReactionInfo,
  UserWallReactionsData,
  WEB_CLIENT_REACTIONS_IMAGES,
  abbrNum,
} from "../helpers/EventStreamUtils";

type StreamReactionsProps = {
  reactionData: UserWallReactionsData;
};

const userReactionsDivStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 7778;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  &.userReactionsDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const reactionAvatarStyle = css`
  width: 3.75em;
  height: 3.75em;
  border-radius: 50%;
  margin: 10px;
  position: absolute;
  top: 60%;
  animation: ${reactionAvatarAnimation} 2s ease-out forwards;
`;

const reactionReceivedStyle = (level: number) => css`
  top: 78%;
  animation: ${reactionAnimation} 3s infinite forwards;
  animation-iteration-count: 1;
  &.userReactionReceivedDiv.level${level} {
    position: absolute;
    width: 3em;
    height: 3em;
    top: ${Math.random() * 100}%;
    left: ${Math.random() * 100}%;
    transform: translate(-50%, -50%);
    z-index: ${level};
  }
`;

const ReactionsTotalDiv = styled.div({
  fontSize: "2rem",
  margin: "0.6em 0",
  position: "absolute",
  top: "15em",
  left: "1.25em",
  color: odienceColors.pureWhite,
  textTransform: "uppercase",
  opacity: 1,
  fontStyle: "italic",
  fontWeight: "bold",
  animation: `${reactionTotalAnimation} 5s infinite forwards`,
  animationIterationCount: 1,
});

const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const StreamReactions = ({ reactionData }: StreamReactionsProps) => {
  const { users, level, total, timestamp } = reactionData;
  const Over1000UserWallReactions = 1000;
  const showReactionTotal = (total: number) => (
    <ReactionsTotalDiv className="reactionsTotalDiv">
      {abbrNum(total, 2)}+
    </ReactionsTotalDiv>
  );

  const showUserReactionAvatarsOnScreen = (
    level: number,
    reaction: UserReactionInfo,
    timestamp: number
  ) => {
    const reactionUrl = WEB_CLIENT_REACTIONS_IMAGES[reaction.reaction];
    return (
      <div
        className="userReactionAvatar"
        css={reactionAvatarStyle}
        key={reaction.sip + reaction.reaction + timestamp} // Unique key for each reaction received
      >
        <AvatarImg
          src={reaction.avatar || "/odience/user/user.png"}
          onError={(e) => (e.currentTarget.src = "/odience/user/user.png")}
        />
        {Array.from({ length: level > 1 ? level * 2 : 1 }).map((_, i) => (
          <div
            className={`userReactionReceivedDiv level${level}`}
            css={reactionReceivedStyle(level)}
            key={i}
          >
            <img
              src={reactionUrl}
              alt="reaction"
              css={{
                animation: `${leftRight} 1.5s alternate infinite ease-in-out`,
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="userReactionsDiv" css={userReactionsDivStyle}>
      {total > Over1000UserWallReactions && showReactionTotal(total)}
      {users.map((reaction) =>
        showUserReactionAvatarsOnScreen(level, reaction, timestamp)
      )}
    </div>
  );
};

export default StreamReactions;
