import { odienceColors } from "@/styles/global.styles";
import { isMobile } from "@/utils/helpers/Utils";
import { css } from "@emotion/react";
import { useImperativeHandle, useRef } from "react";
import {
  CarouselOrientationType,
  FeedItem,
  FeedItemList,
} from "../helpers/EventStreamUtils";

type FeedItemCarouselProps = {
  feedItems: FeedItemList;
  carouselOpen: boolean;
  carouselOrientation: CarouselOrientationType;
  scrollCarousel: (direction: string) => void;
  selectMiniItem: (id: string) => void;
  selectedMiniItemId: string | null;
  carouselRef;
  isStreamFullScreen: boolean;
};

export type FeedItemCarouselRef = {
  getHeight: () => number;
  getWidth: () => number;
};

const FeedItemCarousel = ({
  ref,
  feedItems,
  carouselOpen,
  carouselOrientation,
  scrollCarousel,
  selectMiniItem,
  selectedMiniItemId,
  carouselRef,
  isStreamFullScreen,
}: FeedItemCarouselProps & {
  ref?: React.RefObject<FeedItemCarouselRef | null>;
}) => {
  const mainDivRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => ({
    getHeight: () => {
      const rect = mainDivRef.current?.getBoundingClientRect();
      if (!rect || !mainDivRef.current) {
        return 0;
      }

      const computedStyle = getComputedStyle(mainDivRef.current);
      const bottom = parseInt(computedStyle.bottom);

      return (rect?.height || 0) + bottom;
    },
    getWidth: () => {
      const rect = mainDivRef.current?.getBoundingClientRect();

      if (!rect || !mainDivRef.current) {
        return 0;
      }

      const computedStyle = getComputedStyle(mainDivRef.current);
      const left = parseInt(computedStyle.left);

      return (rect?.width || 0) + left;
    },
  }));

  return (
    !isMobile() &&
    feedItems &&
    Object.entries(feedItems).length > 0 && (
      <div
        css={miniCarouselStyles(
          carouselOpen,
          carouselOrientation,
          isStreamFullScreen
        )}
        ref={mainDivRef}
      >
        <button
          type="button"
          onClick={() => scrollCarousel("left")}
          css={buttonStyles(carouselOrientation, "left")}
        >
          <img
            src="/odience/user/sort_down.svg"
            alt="Scroll Left"
            css={{ width: "1.25em", height: "1.25em" }}
          />
        </button>
        <div ref={carouselRef} css={carouselInnerStyles(carouselOrientation)}>
          {Object.entries(feedItems).map(
            ([id, feedItem]: [string, FeedItem], index: number) => (
              <button
                type="button"
                key={id}
                className={`miniFeedItem ${id} ${index === 0 ? "selected" : ""}`}
                onClick={() => selectMiniItem(id)}
                css={miniFeedItemStyles(
                  selectedMiniItemId === id,
                  odienceColors
                )}
              >
                <img
                  src={feedItem.image_link}
                  alt={feedItem.title}
                  css={{ width: "5em", height: "auto" }}
                />
              </button>
            )
          )}
        </div>
        <button
          type="button"
          onClick={() => scrollCarousel("right")}
          css={buttonStyles(carouselOrientation, "right")}
        >
          <img
            src="/odience/user/sort_down.svg"
            alt="Scroll Right"
            css={{ width: "1.25em", height: "1.25em" }}
          />
        </button>
      </div>
    )
  );
};

export default FeedItemCarousel;

const navigationButtonRotation = (
  carouselOrientation: string,
  direction: string
): string => {
  if (carouselOrientation === "vertical") {
    return direction === "left" ? "rotate(180deg)" : "rotate(0deg)";
  }
  if (carouselOrientation === "horizontal") {
    return direction === "left" ? "rotate(90deg)" : "rotate(270deg)";
  }
  return "";
};

const miniCarouselStyles = (
  carouselOpen: boolean,
  carouselOrientation: string,
  isFullScreen: boolean
) =>
  css({
    position: isFullScreen ? "fixed" : "absolute",
    zIndex: 103,
    display: "flex",
    visibility: carouselOpen ? "visible" : "hidden",
    gap: "1.25em",
    padding: "1.25em",
    transition: "0.5s opacity ease-in-out",
    backgroundColor: odienceColors.voidBlack,
    flexDirection: carouselOrientation === "vertical" ? "column" : "row",
    maxHeight: carouselOrientation === "vertical" ? "32vw" : "15vh",
    maxWidth: carouselOrientation === "vertical" ? "15vh" : "32vw",
    left: "0.5em",
    bottom: "0.5em",
    borderRadius: "10px",
    justifyContent: "center",
  });

const buttonStyles = (carouselOrientation: string, direction: string) =>
  css({
    cursor: "pointer",
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: navigationButtonRotation(carouselOrientation, direction),
  });

const carouselInnerStyles = (carouselOrientation: string) =>
  css({
    display: "flex",
    flexDirection: carouselOrientation === "vertical" ? "column" : "row",
    gap: "1.25em",
    alignItems: "center",
    transition: "0.5s opacity ease-in-out",
    backgroundColor: odienceColors.voidBlack,
    overflow: "hidden",
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: odienceColors.ironGray,
    },
    "&::-webkit-scrollbar": {
      backgroundColor: odienceColors.nightBlack,
      scrollbarColor: odienceColors.nightBlack,
      scrollbarWidth: "thin",
      width: "0.6em",
      height: "0.6em",
    },
  });

const miniFeedItemStyles = (selected: boolean, colors: any) =>
  css({
    maxWidth: "5em",
    maxHeight: "5em",
    borderRadius: "10px",
    background: colors.primaryTextColor,
    overflow: "hidden",
    cursor: "pointer",
    flexShrink: 0,
    opacity: selected ? 1 : 0.6,
    transform: selected ? "scale(0.9)" : "scale(1)",
    "&:hover": {
      opacity: 1,
      transform: "scale(0.9)",
    },
  });
