export type StreamControllerCallbacks = {
  onDrag: () => void;
};

class StreamController {
  isUserInteracting = false;
  currMouseX = 0;
  currMouseY = 0;
  pressX = 0;
  pressY = 0;
  dc: RTCDataChannel;
  dcTimeout: number | null = null;
  inputTimer: NodeJS.Timeout | null = null;
  pressTimer: NodeJS.Timeout | null = null;
  inputEnabled = true;
  firstLoad = true;
  strServerId = "";
  receiveID = "";
  callbacks: StreamControllerCallbacks;
  divIdForInteraction: string;
  arrowKeyPressed = false; // Keep track if any arrow key is pressed
  keyMovementSpeed = 25; // Speed for arrow key movement

  constructor(
    strServerId: string,
    receiveID: string,
    dc: RTCDataChannel,
    callbacks: StreamControllerCallbacks,
    divIdForInteraction: string
  ) {
    this.strServerId = strServerId;
    this.receiveID = receiveID;
    this.dc = dc;
    this.callbacks = callbacks;
    this.divIdForInteraction = divIdForInteraction;

    const media = document.getElementById(divIdForInteraction);

    if (media) {
      media.addEventListener(
        "wheel",
        this.onDocumentMouseScroll.bind(this),
        false
      );
      media.addEventListener(
        "mousedown",
        this.onDocumentMouseDown.bind(this),
        false
      );
      media.addEventListener(
        "mousemove",
        this.onDocumentMouseMove.bind(this),
        false
      );
      window.addEventListener(
        "mouseup",
        this.onDocumentMouseUp.bind(this),
        false
      );
      media.addEventListener(
        "touchstart",
        this.onDocumentTouchStart.bind(this),
        false
      );
      media.addEventListener(
        "touchmove",
        this.onDocumentTouchMove.bind(this),
        false
      );
      window.addEventListener(
        "touchend",
        this.onDocumentTouchEnd.bind(this),
        false
      );
      window.addEventListener(
        "touchcancel",
        this.onDocumentTouchEnd.bind(this),
        false
      );
      window.addEventListener("keydown", this.onArrowKeyDown.bind(this), false);
      window.addEventListener("keyup", this.onArrowKeyUp.bind(this), false);
    }
  }

  isTouchInsideMedia(touch: Touch) {
    const media = document.getElementById(this.divIdForInteraction);

    if (media) {
      const mediaRect = media.getBoundingClientRect();

      return (
        mediaRect &&
        touch.clientX >= mediaRect.left &&
        touch.clientX <= mediaRect.right &&
        touch.clientY >= mediaRect.top &&
        touch.clientY <= mediaRect.bottom
      );
    }
  }

  close() {
    window.removeEventListener("wheel", this.onDocumentMouseScroll);
    window.removeEventListener("mousedown", this.onDocumentMouseDown);
    window.removeEventListener("mousemove", this.onDocumentMouseMove);
    window.removeEventListener("mouseup", this.onDocumentMouseUp);
    window.removeEventListener("touchstart", this.onDocumentTouchStart);
    window.removeEventListener("touchmove", this.onDocumentTouchMove);
    window.removeEventListener("touchend", this.onDocumentTouchEnd);
    window.removeEventListener("touchcancel", this.onDocumentTouchEnd);
    window.removeEventListener("keydown", this.onArrowKeyDown);
    window.removeEventListener("keyup", this.onArrowKeyUp);
  }

  onArrowKeyDown = (event: KeyboardEvent) => {
    const media = document.getElementById(this.divIdForInteraction);

    if (media) {
      const mediaWidth = media.clientWidth;
      const mediaHeight = media.clientHeight;

      // Set the initial value of currMouseX and currMouseY to the center of the media element
      if (!this.arrowKeyPressed) {
        this.currMouseX = mediaWidth / 2;
        this.currMouseY = mediaHeight / 2;
      }

      if (
        ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)
      ) {
        if (!this.arrowKeyPressed) {
          this.isUserInteracting = true;
          this.arrowKeyPressed = true;
          this.sendMouseDown(this.currMouseX, this.currMouseY);
        }

        // Update mouse position based on key pressed
        if (event.key === "ArrowUp") this.currMouseY += this.keyMovementSpeed;
        if (event.key === "ArrowDown") this.currMouseY -= this.keyMovementSpeed;
        if (event.key === "ArrowLeft") this.currMouseX += this.keyMovementSpeed;
        if (event.key === "ArrowRight")
          this.currMouseX -= this.keyMovementSpeed;

        this.sendMouseDrag(this.currMouseX, this.currMouseY);
      }
    }
  };

  onArrowKeyUp = (event: KeyboardEvent) => {
    if (
      ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)
    ) {
      // Simulate mouse up when the arrow key is released
      this.arrowKeyPressed = false;
      this.isUserInteracting = false;
      this.sendMouseUp();
    }
  };

  onDocumentMouseDown = (event: MouseEvent) => {
    this.isUserInteracting = true;
    this.sendMouseDown(event.clientX, event.clientY);
  };

  onDocumentMouseScroll = (event: WheelEvent) => {
    event.preventDefault();
    this.sendMouseScroll(event.deltaY, this.currMouseX, this.currMouseY);
  };

  onDocumentTouchStart = (event: TouchEvent) => {
    this.isUserInteracting = true;
    this.sendMouseDown(
      event.changedTouches[0].pageX,
      event.changedTouches[0].pageY
    );
  };

  onDocumentTouchMove = (event: TouchEvent) => {
    if (
      this.isUserInteracting &&
      this.isTouchInsideMedia(event.changedTouches[0])
    ) {
      this.sendMouseDrag(
        event.changedTouches[0].clientX,
        event.changedTouches[0].clientY
      );
    }
  };

  onDocumentTouchEnd = () => {
    this.isUserInteracting = false;
    this.sendMouseUp();
  };

  onDocumentMouseMove = (event: MouseEvent) => {
    if (this.isUserInteracting) {
      this.sendMouseDrag(event.clientX, event.clientY);
    }
    this.currMouseX = event.clientX;
    this.currMouseY = event.clientY;
  };

  onDocumentMouseUp = () => {
    this.isUserInteracting = false;
    this.sendMouseUp();
  };

  recenter() {
    if (this.dc.readyState === "open") {
      this.dc.send(
        JSON.stringify({
          id: this.strServerId,
          type: "setCordination",
          y: 0,
          receiver: this.receiveID,
        })
      );
    }
  }

  sendMouseDown(x: number, y: number) {
    const streamMainTag = document.getElementById("streamMain");
    if (streamMainTag) {
      streamMainTag.classList.add("hide-cursor");
    }
    const elem = document.getElementById(this.divIdForInteraction);
    if (elem) {
      const rect = elem.getBoundingClientRect();
      if (
        x >= rect.left &&
        x <= rect.left + rect.width &&
        y >= rect.top &&
        y <= rect.top + rect.height
      ) {
        x = (x - rect.left) / rect.width;
        y = (y - rect.top) / rect.height;

        this.pressX = x;
        this.pressY = y;
        this.pressTimer = setTimeout(this.sendLongPress.bind(this), 1000);
        if (this.dc.readyState === "open") {
          this.dc.send(
            JSON.stringify({
              id: this.strServerId,
              type: "mouseDown",
              x: x,
              y: y,
              receiver: this.receiveID,
            })
          );
        }
      }
    }
  }

  sendMouseScroll(delta: number, x: number, y: number) {
    const elem = document.getElementById(this.divIdForInteraction);
    if (elem) {
      const rect = elem.getBoundingClientRect();
      if (
        x >= rect.left &&
        x <= rect.left + rect.width &&
        y >= rect.top &&
        y <= rect.top + rect.height
      ) {
        x = (x - rect.left) / rect.width;
        y = (y - rect.top) / rect.height;

        if (this.dc.readyState === "open") {
          this.dc.send(
            JSON.stringify({
              id: this.strServerId,
              type: "mouseScroll",
              delta: delta,
              x: x,
              y: y,
              receiver: this.receiveID,
            })
          );
        }
      }
    }
  }

  sendMouseDrag(x: number, y: number) {
    this.callbacks.onDrag();
    const elem = document.getElementById(this.divIdForInteraction);
    if (elem) {
      const rect = elem.getBoundingClientRect();
      x = (x - rect.left) / rect.width;
      y = (y - rect.top) / rect.height;
    }

    if (
      this.pressX - x > 0.005 ||
      this.pressX - x < -0.005 ||
      this.pressY - y > 0.005 ||
      this.pressY - y < -0.005
    ) {
      clearTimeout(this.pressTimer!);
      this.pressTimer = null;
    }
    if (this.inputEnabled) {
      this.inputEnabled = false;
      // Set the movement to 90 FPS
      this.inputTimer = setTimeout(this.enableInput.bind(this), 1000 / 90);
      if (this.dc.readyState === "open" && !this.inputEnabled) {
        this.dc.send(
          JSON.stringify({
            id: this.strServerId,
            type: "mouseDrag",
            x: x,
            y: y,
            receiver: this.receiveID,
          })
        );
      }
    }
  }

  sendMouseUp() {
    const streamMainTag = document.getElementById("streamMain");
    if (streamMainTag) {
      streamMainTag.classList.remove("hide-cursor");
    }
    clearTimeout(this.pressTimer!);
    this.pressTimer = null;
    if (this.dc.readyState === "open") {
      this.dc.send(
        JSON.stringify({
          id: this.strServerId,
          type: "mouseUp",
          receiver: this.receiveID,
        })
      );
    }
  }

  sendLongPress() {
    const x = this.pressX;
    const y = this.pressY;

    if (this.dc.readyState === "open") {
      this.dc.send(
        JSON.stringify({
          id: this.strServerId,
          type: "mouseLongPress",
          x: x,
          y: y,
          receiver: this.receiveID,
        })
      );
    }
    clearTimeout(this.pressTimer!);
    this.pressTimer = null;
  }

  enableInput() {
    this.inputEnabled = true;
    clearTimeout(this.inputTimer!);
    this.inputTimer = null;
  }
}

export default StreamController;
