import { odienceColors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { MediaPool } from "../helpers/EventStreamUtils";

type MediaPoolOverlayProps = {
  mediaPool: MediaPool;
  isOpen: boolean | undefined;
  handleClose: () => void;
  handleOpen?: () => void;
};

const MediaPoolOverlay = ({
  mediaPool,
  isOpen,
  handleClose,
}: MediaPoolOverlayProps) => {
  const [expandMediaPool, setExpandMediaPool] = useState(false);
  const fullScreenMediaPool = mediaPool?.event.info.fullscreen === "true";
  const mediaPoolControls = mediaPool?.event.info.viewer_controls === "true";

  const handleOpenMedia = () => {
    if (mediaPool?.content?.original_media?.media_url) {
      window.open(mediaPool.content.original_media.media_url, "_blank");
    }
  };

  const mediaContent =
    mediaPool?.content?.original_media?.media_type === "image" ? (
      <img
        src={mediaPool?.content?.original_media?.media_url}
        alt={mediaPool?.content?.original_media?.name}
        css={mediaStyles(fullScreenMediaPool)}
      />
    ) : (
      <video
        src={mediaPool?.content?.original_media?.media_url}
        autoPlay
        controls={mediaPoolControls}
        loop
        css={{ width: "100%", ...mediaStyles(fullScreenMediaPool) }}
      />
    );

  const controlButtons = mediaPoolControls && (
    <div css={controlsStyles}>
      <IconButton
        css={{ backgroundColor: "black", color: odienceColors.pureWhite }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <IconButton>
        <OpenInBrowserIcon
          css={{ backgroundColor: "black", color: odienceColors.pureWhite }}
          onClick={handleOpenMedia}
        />
      </IconButton>
      <IconButton>
        <ExpandMoreIcon
          css={{
            transform: expandMediaPool ? "rotate(90deg)" : "rotate(270deg)",
            color: odienceColors.pureWhite,
          }}
          onClick={() => setExpandMediaPool(!expandMediaPool)}
        />
      </IconButton>
    </div>
  );

  return isOpen ? (
    <div css={overlayStyles(fullScreenMediaPool, expandMediaPool)}>
      <div css={contentContainerStyles(fullScreenMediaPool)}>
        {mediaContent}
        {controlButtons}
      </div>
    </div>
  ) : null;
};

export default MediaPoolOverlay;

const overlayStyles = (
  fullScreenMediaPool: boolean,
  expandMediaPool: boolean
) =>
  css({
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: fullScreenMediaPool ? "100%" : expandMediaPool ? "35vw" : "25vw",
    zIndex: 9999,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "1vh",
  });

const contentContainerStyles = (fullScreenMediaPool: boolean) =>
  css({
    height: fullScreenMediaPool ? "100%" : "fit-content",
    width: fullScreenMediaPool ? "100%" : "fit-content",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
  });

const mediaStyles = (fullScreenMediaPool: boolean) =>
  css({
    height: fullScreenMediaPool ? "100%" : "fit-content",
    border: fullScreenMediaPool
      ? "none"
      : `14px ${odienceColors.pureBlack} solid`,
    borderRadius: fullScreenMediaPool ? "none" : "16px",
  });

const controlsStyles = css({
  width: "5vw",
  backgroundColor: odienceColors.pureBlack,
  display: "flex",
  justifyContent: "space-evenly",
  color: "white",
  fontSize: "1rem",
  flexDirection: "column",
});
